define('slate-payroll-admin/controllers/administration/company/tax-forms', ['exports', 'slate-payroll-admin/mixins/resource-pagination', 'slate-payroll-admin/mixins/ux-ajax-status-hooks'], function (exports, _resourcePagination, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_resourcePagination.default, _uxAjaxStatusHooks.default, {
    filterEmployees: [],
    limitingOptions: [20, 50, 100, 200, 500],

    queryParams: ['page', 'filterEmployees', 'limit', 'showFilters'],
    filterByEmployees: Ember.A(),
    page: 1,
    limit: 20,
    showFilters: false,

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },
      setLimit: function setLimit(opt) {
        this.set('limit', opt);
      },
      selectAllEmployees: function selectAllEmployees() {
        this.get('filterByEmployees').addObjects(this.get('employees').mapBy('id'));
      },
      deselectAllEmployees: function deselectAllEmployees() {
        this.get('filterByEmployees').removeObjects(this.get('filterByEmployees'));
      },
      filter: function filter() {
        var filterEmployees = this.get('filterByEmployees');

        this.setProperties({
          filterEmployees: filterEmployees,
          page: 1
        });
      },
      clearFilters: function clearFilters() {
        this.setProperties({
          filterByEmployees: Ember.A(),
          filterEmployees: [],
          page: 1
        });
      },
      deleteTaxForm: function deleteTaxForm(taxForm) {
        var _this = this;

        var _taxForm$attributes = taxForm.attributes,
            year = _taxForm$attributes.year,
            type = _taxForm$attributes.type;


        if (!confirm('Are you sure you want to delete this ' + type + ' for year ' + year + '?')) {
          return;
        }

        this.ajaxStart();

        taxForm.destroyRecord().then(function () {
          _this.ajaxSuccess();
          _this.send('refresh');
        }).catch(this.ajaxError.bind(this));
      }
    }
  });
});