define('slate-payroll-admin/components/data-explorer/report-item', ['exports', 'slate-payroll-admin/mixins/render-tooltips'], function (exports, _renderTooltips) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_renderTooltips.default, {
    actions: {
      deleteReport: function deleteReport() {
        this.get('report').destroyRecord();
      }
    }
  });
});