define('slate-payroll-admin/routes/index', ['exports', 'slate-payroll-admin/mixins/authenticated-route'], function (exports, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRoute.default, {
    model: function model() {
      return this.transitionTo('administration');
    }
  });
});