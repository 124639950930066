define('slate-payroll-admin/controllers/administration/sync', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    syncEventSorting: ['start:desc'],
    syncEvents: Ember.A(),
    oldSyncEvents: Ember.A(),
    syncEventsSorted: Ember.computed.sort('syncEvents', 'syncEventSorting'),
    oldSyncEventsSorted: Ember.computed.sort('oldSyncEvents', 'syncEventSorting'),

    showStatus: Ember.computed.or('syncing', 'doneSyncing'),

    _syncError: function _syncError(err) {
      this.setProperties({
        syncError: err,
        syncing: false
      });

      this._destroyListeners();
    },

    _syncEvent: function _syncEvent(eventData) {
      this.get('syncEvents').addObject({
        start: new Date(),
        text: eventData.msg,
        checked: eventData.success
      });

      if (eventData.done) {
        this.get('oldSyncEvents').addObjects(this.get('syncEvents'));

        this.setProperties({
          syncing: false,
          doneSyncing: true,
          syncEvents: Ember.A()
        });

        this._destroyListeners();
      }
    },

    _destroyListeners: function _destroyListeners() {
      var socket = this.socket.get('connection');

      socket.off('sync-error');
      socket.off('sync-event');
    },

    actions: {
      beginSync: function beginSync() {
        var socket = this.socket.get('connection');

        this.setProperties({
          syncing: true,
          syncError: null,
          doneSyncing: false
        });

        socket.emit('init-sync', { auth: this.session.get('content.token') });
        socket.on('sync-error', this._syncError.bind(this));
        socket.on('sync-event', this._syncEvent.bind(this));
      }
    }
  });
});