define('slate-payroll-admin/mixins/route-context', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    needs: ['application'],
    currentPath: Ember.computed.alias('controllers.application.currentPath'),
    routeDefinitions: {},

    routeContext: Ember.computed('currentPath', function () {
      var c = this.get('routeDefinitions.' + this.get('currentPath').split('.').pop());
      return c ? c : '';
    }),

    inSubRoute: Ember.computed('currentPath', 'root', function () {
      var _root = this.get('root');
      return _root ? !(this.get('currentPath').split('.').slice(-2).join('.') === _root + '.index' || this.get('currentPath').split('.').pop() === _root) : undefined;
    })
  });
});