define('slate-payroll-admin/controllers/administration/companies/add/complete', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    indexController: Ember.inject.controller('administration/companies/add/index'),
    logo: Ember.computed.reads('indexController.file.firstObject'),
    overtimeRules: Ember.A(),

    updateStatus: function updateStatus(status) {
      this.set('savingStatus', status);
    },
    _end: function _end(err) {
      var errMsg = err && err.responseText ? err.responseText : err;

      if (err) {
        Ember.Logger.error(err.stack);
      }

      this.setProperties({
        saveError: errMsg,
        saving: false,
        savingStatus: null
      });
    },
    _promiseMapSave: function _promiseMapSave(array) {
      var arrayLength = array ? array.get ? array.get('length') : array.length : null;
      return arrayLength && arrayLength > 0 ? Ember.RSVP.all(array.map(function (item) {
        Ember.Logger.debug('Complete Controller :: Saving item...');
        return item.save();
      })) : Ember.RSVP.Promise.resolve();
    },
    saveDependencies: function saveDependencies(dependencies, model) {
      var self = this;

      return Ember.RSVP.all(dependencies.map(function (dependency) {
        Ember.Logger.debug('Complete Controller :: ', dependency, Ember.get(model, dependency));
        return self._promiseMapSave(Ember.get(model, dependency));
      }));
    },


    // BROWSER POLYFILL WARNING:
    // The FormData Object is only available to following browsers:
    // IE 10+, Firefox 4.0+, Chrome 7+, Safari 5+, Opera 12+
    // Will need a polyfill to support less than versions
    uploadFile: function uploadFile(file) {
      var self = this;
      var formData = new FormData();

      formData.append('file', file);

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          type: 'POST',
          url: '/api/companies/logo',
          data: formData,
          processData: false,
          contentType: false,
          cache: false,
          xhr: function xhr() {
            var xhr = new window.XMLHttpRequest();

            xhr.upload.addEventListener('progress', function (evt) {
              if (evt && evt.lengthComputable) {
                self.set('uploadProgress', Math.round(evt.loaded / evt.total * 100));
              }
            }, false);

            return xhr;
          },
          success: resolve,
          error: reject
        });
      });
    },
    attachLogo: function attachLogo(result) {
      this.set('logo', result.url);
      return this;
    },


    actions: {
      complete: function complete() {
        var _this = this;

        var company = this.get('model'),
            logo = this.get('logo');

        var end = this._end.bind(this),
            status = this.updateStatus.bind(this),
            overtimeRules = this.get('overtimeRules');

        end();
        this.set('saving', true);
        status('Saving Company...');

        company.save().then(function () {
          status('Saving Dependencies...');
          return _this.saveDependencies(['locations', 'departments', 'users', 'companyPaycodes', 'employees'], company);
        }).then(function (results) {
          (results[results.length - 1] || []).forEach(function (savedRecord) {
            savedRecord.get('bankAccounts').filterBy('id', null).invoke('deleteRecord');
          });

          status('Updating Company...');
          // Reassert these overtimeRules we've been saving, they have been updated with the paycode assertion
          // Fixes issue with id getting generated automatically for subrecords (1 becomes 2 identical)
          company.get('overtimeRules').addObjects(overtimeRules);

          var lastSaveCompany = function lastSaveCompany() {
            company.set('defaultPaycode', company.get('companyPaycodes').findBy('isDefault', true));
            return company.save();
          };

          return logo ? _this.uploadFile(logo).then(_this.attachLogo.bind(company)).then(lastSaveCompany) : lastSaveCompany();
        }).then(function (companyResult) {
          return _this._promiseMapSave(companyResult.get('employees')).then(function (result) {
            (result || []).forEach(function (savedRecord) {
              return savedRecord.get('bankAccounts').filterBy('id', null).invoke('deleteRecord');
            });
            return companyResult;
          });
        }).then(function (companyResult) {
          end();

          if (company.get('overtimeRules.length')) {
            companyResult.set('overtimeRules', companyResult.get('overtimeRules').filter(function (rule) {
              return rule.get('id') !== null;
            }));
          }

          _this.set('overtimeRules', Ember.A());
          _this.transitionToRoute('administration.company', companyResult);
        }).catch(end);
      }
    }
  });
});