define('slate-payroll-admin/components/list/request-item', ['exports', 'slate-payroll-admin/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RequestItemComponent = Ember.Component.extend(_uxAjaxStatusHooks.default, {
    classNames: ['list-group-item', 'clearfix'],

    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      Ember.run.next(function () {
        if (_this.get('expanded') !== null) {
          _this.set('showingDetails', _this.get('expanded'));
        }
      });
    },


    actions: {
      toggleExpanded: function toggleExpanded() {
        this.toggleProperty('showingDetails');
        if (!this.get('showingDetails')) {
          this.get('onCollapse')();
        }
      },
      complete: function complete() {
        var _this2 = this;

        var requests = this.get('model.requests');
        this.ajaxStart();

        Ember.RSVP.map(requests.toArray(), function (request) {
          request.set('consumed', new Date());
          request.set('diff', request.get('diff').map(function (d) {
            return Object.assign(d, { snapshot: null });
          }));
          return request.save();
        }).then(function () {
          _this2.ajaxSuccess();
          _this2.get('onCompleted')();
        }).catch(this.ajaxError.bind(this));
      }
    }
  });

  RequestItemComponent.reopenClass({
    positionalParams: ['model']
  });

  exports.default = RequestItemComponent;
});