define('slate-payroll-admin/controllers/administration/company/groups/add-location', ['exports', 'slate-payroll-admin/config/app-data'], function (exports, _appData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    companyController: Ember.inject.controller('administration/company'),
    showModal: true,
    modalId: 'add-location-modal',
    modalTitle: '<h2><i class="fa fa-fw fa-plus"></i> Add Location</h2>',
    states: _appData.states,
    disabled: Ember.computed('model.name', function () {
      return this.get('model.name') ? false : true;
    }),

    _end: function _end(err) {
      var errMsg = err && err.responseText ? err.responseText : err;

      if (errMsg && errMsg.errors) {
        errMsg = errMsg.errors.mapBy('detail').join(', ');
      }

      if (err) {
        Ember.Logger.error(err);
      }

      this.setProperties({
        locationError: errMsg,
        savingLocation: false
      });
    },
    transitionToParentRoute: function transitionToParentRoute() {
      this.transitionToRoute('administration.company.groups', this.get('companyController.model.id'));
      this.send('refresh');
    },


    actions: {
      cancel: function cancel() {
        this.transitionToParentRoute();
      },
      saveLocation: function saveLocation() {
        var _this = this;

        var location = this.get('model');
        var end = this._end.bind(this);

        end();
        this.set('savingLocation', true);

        location.save().then(function () {
          end();
          _this.transitionToParentRoute();
        }).catch(end);
      }
    }
  });
});