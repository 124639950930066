define('slate-payroll-admin/mixins/controller-abstractions/add-edit', ['exports', 'slate-payroll-admin/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_uxAjaxStatusHooks.default, {
    enableNotify: true,
    transitionWithModel: true,

    _validateModel: function _validateModel(model) {
      var fields = this.get('requireFields');

      if (!fields) {
        return false;
      }

      var invalidFields = [];

      fields.forEach(function (field) {
        if (!Ember.get(model, field)) {
          invalidFields.push(field);
        }
      });

      return invalidFields.length > 0 ? invalidFields : false;
    },
    _afterSave: function _afterSave(record) {
      var transitionAfterSave = this.get('transitionAfterSave');

      if (transitionAfterSave) {
        var transitionArgs = [transitionAfterSave];

        if (this.get('transitionWithModel')) {
          transitionArgs.push(record.get('id'));
        }

        this.transitionToRoute.apply(this, transitionArgs);
      }
    },


    actions: {
      save: function save(model) {
        var _this = this;

        var _model = model || this.get('model');

        if (!_model) {
          return;
        }

        this.ajaxStart();

        var invalid = this._validateModel(_model);

        if (invalid) {
          var requireFieldDescriptors = Ember.get(this, 'requireFieldDescriptors'),
              invalidMessage = 'You must specify these fields: ' + invalid.map(function (field) {
            return requireFieldDescriptors ? requireFieldDescriptors[field] || field : field;
          }).join(', ');

          this.ajaxError(invalidMessage, true);
          return;
        }

        _model.save().then(function (record) {
          _this.ajaxSuccess('Successfully saved.');
          _this._afterSave(record);

          if (_this.afterSave && typeof _this.afterSave === 'function') {
            _this.afterSave(record);
          }
        }).catch(this.ajaxError.bind(this));
      }
    }
  });
});