define('slate-payroll-admin/routes/administration/company/custom-reports/new', ['exports', 'slate-payroll-admin/mixins/route-abstractions/add'], function (exports, _add) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_add.default, {
    modelName: 'custom-report',

    model: function model() {
      var company = this.modelFor('administration.company');

      this.set('modelDefaults', {
        company: company
      });

      return this._super.apply(this, arguments);
    }
  });
});