define('slate-payroll-admin/components/data-explorer/data-point-item', ['exports', 'slate-payroll-admin/utils/plural-array-descriptor', 'slate-payroll-admin/mixins/render-tooltips', 'moment'], function (exports, _pluralArrayDescriptor, _renderTooltips, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var fm = function fm(date) {
    return (0, _moment.default)(date).format('MMM Do YYYY h:mma');
  };

  exports.default = Ember.Component.extend(_renderTooltips.default, {
    classNames: ['data-point-item'],
    attributeBindings: ['draggable'],
    draggable: true,

    computedValue: Ember.computed('dataPoint.{value,point.isRelationship,point.relationshipModel}', function () {
      var dataPointValue = this.get('dataPoint.value'),
          dataPoint = this.get('dataPoint');

      if (dataPoint.get('isRelationship')) {
        return Ember.isArray(dataPointValue) ? (0, _pluralArrayDescriptor.default)(dataPointValue, 'item') : dataPointValue;
      } else if (dataPoint.get('isDate')) {
        var start = dataPointValue.start,
            end = dataPointValue.end,
            rangeType = dataPointValue;

        if (rangeType && (typeof rangeType === 'undefined' ? 'undefined' : _typeof(rangeType)) !== 'object') {
          return rangeType;
        } else if (start && end) {
          return fm(start) + ' - ' + fm(end);
        } else {
          return start ? fm(start) : fm(end);
        }
      } else {
        return dataPointValue;
      }
    }),

    dragStart: function dragStart(event) {
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.setData('dataPoint', this.get('dataPoint'));
      this.set('dataPoint.dragging', true);
    },
    dragEnd: function dragEnd() {
      var _this = this;

      // Queue up the undrag set, prevents issues when dragging and not dropping
      // on drop location that does this action.
      Ember.run.later(function () {
        _this.set('dataPoint.dragging', true);
      }, 100);
    },


    actions: {
      deleteDataPoint: function deleteDataPoint() {
        var callback = this.get('onDelete');
        callback(this.get('dataPoint'));
      }
    }
  });
});