define('slate-payroll-admin/controllers/administration/data-explorer/index/query', ['exports', 'slate-payroll-admin/config/models', 'slate-payroll-admin/utils/title-case', 'slate-payroll-admin/mixins/ux-ajax-status-hooks', 'ember-inflector'], function (exports, _models, _titleCase, _uxAjaxStatusHooks, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var inflector = new _emberInflector.default(_emberInflector.default.defaultRules);

  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    availableQueryModels: _models.availableQueryModels.map(function (modelName) {
      return {
        modelName: modelName,
        title: (0, _titleCase.default)([inflector.pluralize(modelName).replace(/-/g, ' ')])
      };
    }),

    queryModelTitle: Ember.computed('model.queryModel', function () {
      return (0, _titleCase.default)([inflector.pluralize(this.get('model.queryModel')).replace(/-/g, ' ')]);
    }),

    actions: {
      addDataPoint: function addDataPoint(dataPoint) {
        this.get('model.dataPoints').addObject(dataPoint);
      },
      saveAndRun: function saveAndRun() {
        var _this = this;

        var model = this.get('model');

        this.ajaxStart();

        model.save().then(function (queryRecord) {
          _this.ajaxSuccess();
          _this.transitionToRoute('administration.data-explorer.index.reports.run', queryRecord.get('id'));
        }).catch(this.ajaxError.bind(this));
      }
    }
  });
});