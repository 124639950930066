define('slate-payroll-admin/models/location', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    name: attr('string'),
    code: attr('string'),
    isPrimary: attr('boolean'),

    addressLine1: attr('string'),
    addressLine2: attr('string'),
    addressCity: attr('string'),
    addressState: attr('string', { defaultValue: 'MT' }),
    addressZipcode: attr('string'),

    company: _emberData.default.belongsTo('company'),

    // Legacy
    legacyLocationId: attr('number'),

    created: attr('date'),

    addressString: Ember.computed('addressLine1', 'addressLine2', 'addressCity', 'addressState', 'addressZipcode', function () {
      var data = this.getProperties('addressLine1', 'addressLine2', 'addressCity', 'addressState', 'addressZipcode');

      var ret = data.addressLine1 + ' ';
      if (data.addressLine2) {
        ret += data.addressLine2 + ' ';
      }
      ret += data.addressCity + ', ' + data.addressState + ' ' + data.addressZipcode;

      return ret;
    })
  });
});