define('slate-payroll-admin/models/help-category', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    name: attr('string'),
    articles: _emberData.default.hasMany('help-article', { async: true, inverse: 'category' }),
    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });
});