define('slate-payroll-admin/mixins/render-tooltips', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RenderTooltips = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this._scheduleTooltipRender();
    },
    didInsertElement: function didInsertElement() {
      this._scheduleTooltipRender();
    },


    _scheduleTooltipRender: function _scheduleTooltipRender() {
      if (this.get('selfImplementTooltips') === true) {
        return;
      }

      // Schedule a renderTooltips for afterRender
      Ember.run.scheduleOnce('afterRender', this, this._renderTooltips);
    },

    _renderTooltips: function _renderTooltips() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        // Get jQuery object from view, find tooltip elements, destroy
        // any existing elements and then render the tooltips
        var $tooltips = _this.$ ? _this.$().find('.tooltip-trigger') : Ember.$('.tooltip-trigger');

        if (!$tooltips || !$tooltips.tooltip) {
          return;
        }

        $tooltips.tooltip('destroy');
        $tooltips.tooltip();
      });
    }
  });

  exports.default = RenderTooltips;
});