define('slate-payroll-admin/initializers/inject-session', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var initialize = exports.initialize = function initialize(application) {
    application.inject('controller', 'session', 'modules:session');
    application.inject('route', 'session', 'modules:session');
    application.inject('component', 'session', 'modules:session');
  };

  exports.default = {
    name: 'inject-session',
    after: 'register-modules',

    initialize: initialize
  };
});