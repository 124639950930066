define('slate-payroll-admin/mixins/authenticated-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AuthenticatedRouteMixin = Ember.Mixin.create({
    beforeModel: function beforeModel(transition) {
      if (!this.session.get('authenticated')) {
        this.controllerFor('login').set('savedTransition', transition);
        return this.transitionTo('login');
      }

      this._super.apply(this, arguments);
    },

    authenticationChanged: Ember.observer('session.authenticated', function () {
      if (!this.session.get('authenticated')) {
        this.transitionTo('login');
      }
    })
  });

  AuthenticatedRouteMixin[Ember.NAME_KEY] = 'Slate.AuthenticatedRouteMixin';

  exports.default = AuthenticatedRouteMixin;
});