define('slate-payroll-admin/components/data-explorer/field-column', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    draggable: true,
    attributeBindings: ['draggable'],
    classNames: ['field-column'],
    classNameBindings: ['dragRight', 'dragLeft'],

    _setDragPropertiesFalse: function _setDragPropertiesFalse() {
      this.setProperties({
        dragHovering: false,
        dragRight: false,
        dragLeft: false
      });
    },
    _dragIndexIsDropIndex: function _dragIndexIsDropIndex() {
      return this.get('draggingFieldColumnIndex') === this.get('index');
    },
    dragStart: function dragStart(event) {
      event.dataTransfer.effectAllowed = 'copy';
      event.dataTransfer.dropEffect = 'copy';
      event.dataTransfer.setData('fieldIndex', this.get('index'));
      event.dataTransfer.setData('fieldColumn', true);
      this.setProperties({
        draggingFieldColumn: true,
        draggingFieldColumnIndex: this.get('index'),
        'field.dragging': true
      });
    },
    dragEnd: function dragEnd() {
      var _this = this;

      this.set('draggingFieldColumn', false);
      // Queue up the undrag set, prevents issues when dragging and not dropping
      // on drop location that does this action.
      Ember.run.later(function () {
        _this.set('field.dragging', false);
      }, 100);
    },
    dragEnter: function dragEnter(event) {
      event.preventDefault();
      if (this.get('draggingFieldColumn') && !this._dragIndexIsDropIndex()) {
        this.set('dragHovering', true);
        return false;
      }
    },
    dragLeave: function dragLeave(event) {
      event.preventDefault();
      if (this.get('draggingFieldColumn') && !this._dragIndexIsDropIndex()) {
        this._setDragPropertiesFalse();
      }
    },
    dragOver: function dragOver(event) {
      event.preventDefault();
      if (this.get('draggingFieldColumn') && !this._dragIndexIsDropIndex()) {
        var componentBreakpoint = this.$().width() / 2,
            sidePropertyName = event.originalEvent.offsetX > componentBreakpoint ? 'Right' : 'Left';

        this._setDragPropertiesFalse();
        this.set('drag' + sidePropertyName, true);

        return false;
      }
    },
    drop: function drop(event) {
      event.preventDefault();
      if (!event.dataTransfer.getData('fieldColumn') || this._dragIndexIsDropIndex()) {
        return true;
      }

      var componentBreakpoint = this.$().width() / 2,
          isRight = event.originalEvent.offsetX > componentBreakpoint,
          fieldIndex = event.dataTransfer.getData('fieldIndex'),
          fields = this.get('fields'),
          dragField = fields.objectAt(fieldIndex);

      fields.removeObject(dragField);
      var dropFieldIndex = fields.indexOf(this.get('field')),
          insertIndex = isRight ? dropFieldIndex + 1 : dropFieldIndex;
      fields.insertAt(insertIndex, dragField);

      this._setDragPropertiesFalse();
      return false;
    },


    actions: {
      removeField: function removeField() {
        var action = this.get('removeField');
        action(this.get('field'));
      }
    }
  });
});