define('slate-payroll-admin/controllers/administration/company/payroll', ['exports', 'slate-payroll-admin/mixins/controller-abstractions/add-edit'], function (exports, _addEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_addEdit.default, {
    fixedChanges: Ember.computed('model.{statics,staticHeaders}', function () {
      var rows = this.get('model.statics'),
          allHeaders = this.get('model.staticHeaders'),
          columnsChanged = Ember.A(),
          headers = void 0;

      var changesInclude = function changesInclude(item, i) {
        return columnsChanged.includes(i);
      };

      if (rows) {
        rows.forEach(function (row) {
          if (!row.change) {
            return;
          }

          columnsChanged.addObjects(row.changesTo);
        });
      }

      if (allHeaders) {
        headers = allHeaders.filter(changesInclude);
      }

      return rows && headers ? {
        headers: headers,
        rows: rows.filter(function (row) {
          return row.change;
        }).map(function (row) {
          var columns = row.columns.filter(changesInclude);
          var changesTo = row.changesTo.map(function (changeIndex) {
            var header = allHeaders[changeIndex];

            if (headers.includes(header)) {
              return headers.indexOf(header);
            }
          });

          return Ember.$.extend({}, row, { columns: columns, changesTo: changesTo });
        })
      } : undefined;
    }),

    totalHours: Ember.computed('model', function () {
      var rows = this.get('model.rows');
      return rows.reduce(function (total, row) {
        return total + row.totalHours;
      }, 0);
    }),

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      }
    }
  });
});