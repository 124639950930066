define('slate-payroll-admin/components/form-steps', ['exports', 'slate-payroll-admin/templates/components/form-steps'], function (exports, _formSteps) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _formSteps.default,
    tagName: 'form',

    disableSubmit: Ember.computed.not('stepValid'),
    disableBack: Ember.computed.equal('step', 0),
    steps: Ember.computed.alias('childViews'),
    step: 0,
    _formBindings: Ember.A(),

    didInsertElement: function didInsertElement() {
      this.stepDidChange();
    },


    setStepsIndex: Ember.observer('steps', function () {
      this.get('steps').forEach(function (step, index) {
        step.set('_formIndex', index);
      });
    }),

    stepDidChange: Ember.observer('step', function () {
      var step = this.get('step'),
          formBindings = this.get('_formBindings'),
          validWith = this.get('validWith').objectAt(step);

      if (formBindings.length > 0) {
        this._removeObservers(formBindings, this._setStepValid);
      }

      if (validWith) {
        this._addObservers(validWith, this._setStepValid);
      }

      this._setStepValid();
      Ember.run.scheduleOnce('afterRender', this, this._autofocus);
    }),

    _addObservers: function _addObservers(keys, method) {
      var self = this,
          added = [];

      keys.forEach(function (key) {
        added.push(key);
        self.get('_formBindings').pushObject(key);
        self.get('for').addObserver(key, self, method);
      });
    },

    _removeObservers: function _removeObservers(keys, method) {
      var self = this,
          removed = [];

      keys.forEach(function (key) {
        removed.push(key);
        self.get('for').removeObserver(key, self, method);
      });

      self.set('_formBindings', Ember.A());
    },

    _setStepValid: function _setStepValid() {
      Ember.run.debounce(this, function () {

        var self = this,
            formContext = this.get('for'),
            qual = this.get('qualification'),
            validWith = this.get('validWith').objectAt(this.get('step'));

        if (!validWith) {
          return this.set('stepValid', true);
        }

        var invalid = validWith.filter(function (key) {
          var value = formContext.get(key),
              ret = qual && qual[key] && typeof qual[key] === 'function' ? qual[key].call(formContext, value) : !!value;

          if (typeof ret === 'string' && formContext.get(key) !== ret) {
            self.get('for').set(key, ret);
          }

          return !ret;
        });

        this.set('stepValid', invalid.length < 1);
      }, 100);
    },

    _autofocus: function _autofocus() {
      var step = this.get('steps').objectAt(this.get('step'));

      if (!step) {
        return;
      }

      step._focus.call(step);
    },

    onLast: Ember.computed('step', 'steps', function () {
      return this.get('steps').length === this.get('step') + 1;
    }),

    contextualButtonText: Ember.computed('submitText', 'step', function () {
      if (this.get('onLast')) {
        return this.get('finishText') || 'Finish';
      }

      var showStepNames = this.get('includeStepNames'),
          step = this.get('step'),
          steps = this.get('steps'),
          stepName = steps.objectAt(step + 1).get('name'),
          stepSubmitText = steps.objectAt(step).get('submitText');

      var text = stepSubmitText || this.get('submitText') || 'Next';

      return showStepNames && stepName ? text + ': ' + stepName : text;
    }),

    currentTitle: Ember.computed('step', 'steps.@each.title', function () {
      var step = this.get('steps').objectAt(this.get('step'));
      return step ? step.get('title') : false;
    }),

    allowSkip: Ember.computed('step', 'steps.@each.allowSkip', function () {
      var step = this.get('steps').objectAt(this.get('step'));
      return step ? step.get('allowSkip') : false;
    }),

    submit: function submit(e) {
      e.preventDefault();
      this._next();
    },

    _next: function _next() {
      if (this.get('onLast')) {
        this.get('finish')();
      } else {
        this.incrementProperty('step');
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._removeObservers(this.get('_formBindings'), this._setStepValid);
    },

    actions: {
      back: function back() {
        this.decrementProperty('step');
      },

      skip: function skip() {
        this._next();
      }
    }
  });
});