define('slate-payroll-admin/modules/socket', ['exports', 'slate-payroll-admin/modules/socket-events/event-manager', 'slate-payroll-admin/config/environment'], function (exports, _eventManager, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    connected: false,
    connection: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('session', this.container.lookup('modules:session'));
    },

    connect: function connect() {
      var socket = io.connect(_environment.default.socketAddress);

      this.set('connection', socket);

      var self = this;

      socket.on('connect', function () /* data */{
        self.set('connected', true);

        console.debug('Socket Module :: Connected');
      });

      ['connect_timeout', 'connect_error', 'error'].map(function (e) {
        socket.on(e, self.__socketError);
      });

      socket.on('disconnect', function () {
        console.debug('Socket Module :: Disconnected');

        self.setProperties({
          connected: false
        });
      });

      _eventManager.default.get('events').forEach(function (ev) {
        var name = ev.name,
            hook = ev.hook;

        if (!name || !hook || typeof hook !== 'function') {
          return;
        }

        console.debug('Socket Module :: Registering event:', name);

        socket.on(name, ev.hook.bind(self));
      });
    },

    __socketError: function __socketError(err) {
      console.error(err);
    }
  });
});