define('slate-payroll-admin/models/admin-user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    email: attr('string'),
    password: attr('string'),
    phone: attr('string'),

    receivePayrollEmails: attr('boolean'),
    super: attr('boolean'),
    tfaDismiss: attr('boolean'),
    tfaMethods: attr('array'),

    permissions: _emberData.default.hasMany('permission', { inverse: false, async: true }),

    activatedOn: attr('date'),
    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });
});