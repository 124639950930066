define('slate-payroll-admin/controllers/administration/company/paycodes/edit', ['exports', 'slate-payroll-admin/config/app-data'], function (exports, _appData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    paycodeTypes: _appData.paycodeTypes,
    paycodeUnits: _appData.paycodeUnits,
    companyController: Ember.inject.controller('administration/company'),
    showModal: true,
    modalId: 'edit-paycode-modal',

    modalTitle: Ember.computed('model.paycode.description', function () {
      return '<h2>Edit ' + this.get('model.paycode.description') + ' Paycode</h2>';
    }),

    _end: function _end(err) {
      var errMsg = err && err.responseText ? err.responseText : err;

      if (errMsg && errMsg.errors) {
        errMsg = errMsg.errors.mapBy('detail').join(', ');
      }

      if (err) {
        Ember.Logger.error(err);
      }

      this.setProperties({
        paycodeError: errMsg,
        savingPaycode: false
      });
    },
    transitionToParentRoute: function transitionToParentRoute() {
      this.transitionToRoute('administration.company.paycodes', this.get('companyController.model.id'));
    },


    actions: {
      discardChanges: function discardChanges() {
        this.get('model').rollbackAttributes();
        this.transitionToParentRoute();
      },
      deletePaycode: function deletePaycode() {
        var _this = this;

        this.get('model').destroyRecord().then(function () {
          _this.transitionToParentRoute();
        });
      },
      savePaycode: function savePaycode() {
        var _this2 = this;

        var paycode = this.get('model');
        var end = this._end.bind(this);

        end();
        this.set('savingPaycode', true);

        paycode.save().then(function () {
          end();
          _this2.transitionToParentRoute();
        }).catch(end);
      }
    }
  });
});