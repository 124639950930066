define('slate-payroll-admin/controllers/administration/admin-users/index', ['exports', 'slate-payroll-admin/mixins/resource-pagination'], function (exports, _resourcePagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_resourcePagination.default, {
    queryParams: ['page'],
    page: 1,
    limit: 20,

    actions: {
      deleteUser: function deleteUser(user) {
        if (!confirm('Are you sure you want to delete this administration user?')) {
          return;
        }

        user.destroyRecord();
      }
    }
  });
});