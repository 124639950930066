define('slate-payroll-admin/controllers/administration/company/settings', ['exports', 'slate-payroll-admin/mixins/controller-abstractions/add-edit', 'slate-payroll-admin/config/app-data', 'moment'], function (exports, _addEdit, _appData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var timezones = _moment.default.tz.names();

  exports.default = Ember.Controller.extend(_addEdit.default, {
    payPeriodFrequencies: _appData.payPeriodFrequencies,
    timezones: timezones,
    workDays: _appData.workDays,
    transitionAfterSave: 'administration.company.index',
    startDay: Ember.computed('model.workWeekStart', function () {
      var start = this.get('model.workWeekStart');
      return _appData.workDays[start].l;
    }),

    addFaxNumber: function addFaxNumber() {
      var faxNumber = this.pendingFaxNumber.trim().replace(/\D/g, '');

      this.set('invalidFaxNumber', false);

      if (faxNumber.length !== 10) {
        this.set('invalidFaxNumber', true);
        return;
      }

      this.model.get('faxNumbers').addObject(faxNumber);
      this.set('pendingFaxNumber', null);
    },
    removeFaxNumber: function removeFaxNumber(faxNum) {
      this.model.get('faxNumbers').removeObject(faxNum);
    }
  });
});