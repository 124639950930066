define('slate-payroll-admin/controllers/administration/company/groups/add-department', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    companyController: Ember.inject.controller('administration/company'),
    showModal: true,
    modalId: 'add-department-modal',
    modalTitle: '<h2><i class="fa fa-fw fa-plus"></i> Add Department</h2>',
    disabled: Ember.computed('model.name', function () {
      return this.get('model.name') ? false : true;
    }),

    _end: function _end(err) {
      var errMsg = err && err.responseText ? err.responseText : err;

      if (errMsg && errMsg.errors) {
        errMsg = errMsg.errors.mapBy('detail').join(', ');
      }

      if (err) {
        Ember.Logger.error(err);
      }

      this.setProperties({
        departmentError: errMsg,
        savingDepartment: false
      });
    },
    transitionToParentRoute: function transitionToParentRoute() {
      this.transitionToRoute('administration.company.groups', this.get('companyController.model.id'));
      this.send('refresh');
    },


    actions: {
      cancel: function cancel() {
        this.transitionToParentRoute();
      },
      saveDepartment: function saveDepartment() {
        var _this = this;

        var department = this.get('model');
        var end = this._end.bind(this);

        end();
        this.set('savingDepartment', true);

        department.save().then(function () {
          end();
          _this.transitionToParentRoute();
        }).catch(end);
      }
    }
  });
});