define("slate-payroll-admin/utils/random-number", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = randomNumber;
  function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }
});