define('slate-payroll-admin/components/input/forms/state-tax', ['exports', 'slate-payroll-admin/config/app-data'], function (exports, _appData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    states: _appData.states,
    filingStatuses: Ember.computed('model.stateTaxName', function () {
      // 2024 refactor: Montana now allows a head of household filing status
      return this.model.stateTaxName === 'MT' ? _appData.filingStatuses : _appData.filingStatuses.filter(function (v) {
        return v !== 'Head of Household';
      });
    }),
    isStep2State: Ember.computed.equal('model.stateTaxName', 'MT'),
    statesIncomeTaxExempt: _appData.statesIncomeTaxExempt,
    tagName: '',
    showMultiState: false,

    secondaryStates: [{
      v: null,
      l: ' -- '
    }].concat(_toConsumableArray(_appData.states.filter(function (x) {
      return x !== 'MT';
    }))),

    isIncomeTaxExemptState: Ember.computed('model.stateTaxName', function () {
      return _appData.statesIncomeTaxExempt.includes(this.model.stateTaxName);
    }),

    isIncomeTaxExemptState2: Ember.computed('model.stateTaxName2', function () {
      return _appData.statesIncomeTaxExempt.includes(this.model.stateTaxName2);
    }),

    updateStateTaxName: function updateStateTaxName(val) {
      this.model.set('stateTaxName', val);

      if (this.isIncomeTaxExemptState) {
        this.model.setProperties({
          stateTaxAllowances: null,
          stateTaxAdditionalWithholding: null,
          stateTaxFilingStatus: null
        });
      }

      if (this.isStep2State) {
        this.model.setProperties({
          stateTaxAllowances: null,
          stateTaxFilingStatus: null
        });
      } else {
        this.model.setProperties({
          stateTaxReducedWithholding: null,
          stateTaxStep2: null
        });
      }
    },
    updateStateTaxName2: function updateStateTaxName2(val) {
      this.model.set('stateTaxName2', val);

      if (this.isIncomeTaxExemptState2 || !val) {
        this.model.setProperties({
          stateTaxAllowances2: null,
          stateTaxAdditionalWithholding2: null,
          stateTaxFilingStatus2: null
        });
      }
    }
  });
});