define('slate-payroll-admin/routes/administration/data-explorer/index/query', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord('query');
    },


    actions: {
      willTransition: function willTransition(transition) {
        var model = this.controller.get('model');

        if (!model.get('isNew')) {
          return true;
        }

        if (!confirm('Are you sure you want to abandon progress on this query?')) {
          transition.abort();
        } else {
          model.destroyRecord();
          return true;
        }
      }
    }
  });
});