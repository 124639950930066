define('slate-payroll-admin/adapters/application', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend({
    namespace: 'admin-api',
    coalesceFindRequests: true
  });
});