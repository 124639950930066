define('slate-payroll-admin/routes/2fa', ['exports', 'slate-payroll-admin/mixins/authenticated-route'], function (exports, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRoute.default, {
    titleToken: 'Two-Factor Authentication',

    model: function model() {
      return this.session.currentUser;
    }
  });
});