define('slate-payroll-admin/routes/administration/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var now = new Date();

      var _handleError = function _handleError(err) {
        self.set('__error', err && err.responseText ? err.responseText : err);
      };

      return Ember.$.getJSON('/admin-api/punches', {
        _count: true,
        timeIn: {
          $lt: now
        },
        $or: [{
          timeOut: {
            $exists: false
          }
        }, {
          timeOut: {
            $gt: now
          }
        }, {
          timeOut: {
            $type: 10 // null over JSON
          }
        }],
        select: 'employee -_id'
      }).fail(_handleError);
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        clockedInCount: model.count ? model.count : 0
      });
    }
  });
});