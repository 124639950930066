define('slate-payroll-admin/modules/session', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Object.extend({
    contentDidChange: Ember.observer('content', function () {
      console.debug('Session :: Content Change');

      this.set('didSetHeaders', false);

      var token = this.get('content.token');

      if (token) {
        console.debug("Session :: Setting up headers...");
        this._setupHeaders(token);
      }

      this.set('authenticated', !!token);
    }),

    destroySession: function destroySession() {
      var self = this;

      if (this.get('content.id')) {
        this.store.find('session', this.get('content.id')).then(function (session) {
          session.destroyRecord();

          self.setProperties({
            authenticated: false,
            content: null
          });
        });
      }

      Ember.$.ajaxSetup({
        headers: {
          'X-API-Token': null
        }
      });
    },

    createSession: function createSession(data) {
      var self = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        (true && !((typeof data === 'undefined' ? 'undefined' : _typeof(data)) === 'object') && Ember.assert('Session#createSession must have data object to create a session', (typeof data === 'undefined' ? 'undefined' : _typeof(data)) === 'object'));


        var session = self.store.createRecord('session', {
          token: data.token,
          expires: data.expiration,
          user: data.user,
          twoFactorRequired: data.twoFactorRequired,
          twoFactorAuthorized: data.twoFactorAuthorized
        });

        session.save().then(function (record) {
          self.setProperties({
            content: record,
            authenticated: true
          });

          self.get('currentUser');

          resolve(record);
        }).catch(reject);
      });
    },

    _setupHeaders: function _setupHeaders(token) {
      (true && !(!!token) && Ember.assert('Session must have token to setup headers', !!token));


      Ember.$.ajaxSetup({
        headers: {
          'X-API-Token': token
        }
      });

      this.set('didSetHeaders', true);
    },

    currentUser: Ember.computed('content.user', 'authenticated', function () {
      if (!this.get('content.user') || !this.get('authenticated')) {
        return;
      }

      (true && !(this.get('content.user')) && Ember.assert('Session must have user id to fetch currentUser', this.get('content.user')));


      return this.store.find('admin-user', this.get('content.user'));
    })
  });
});