define('slate-payroll-admin/routes/administration/paycode/company-paycodes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      sortNameAsc: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var paycode = this.modelFor('administration.paycode');

      var query = {
        paycode: paycode.get('id'),
        page: params.page - 1 || 0,
        limit: this.get('controller.limit') || 25,
        sort: {
          name: params.sortNameAsc ? 1 : -1
        }
      };

      return this.store.query('company-paycode', query);
    }
  });
});