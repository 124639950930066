define('slate-payroll-admin/helpers/address-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.addressDisplay = addressDisplay;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function addressDisplay(_ref) {
    var _ref2 = _slicedToArray(_ref, 5),
        line1 = _ref2[0],
        line2 = _ref2[1],
        city = _ref2[2],
        state = _ref2[3],
        zipcode = _ref2[4];

    var esc = Ember.Handlebars.Utils.escapeExpression;

    var html = '<div class="address">';
    html += '<p class="address-street">' + esc(line1) + '</p>';
    if (line2) {
      html += '<p class="address-line2">' + esc(line2) + '</p>';
    }
    if (city && state) {
      html += '<p class="address-city-line">' + esc(city) + ', ' + esc(state) + ' ' + esc(zipcode) + '</p></div>';
    }

    return Ember.String.htmlSafe(html);
  }

  exports.default = Ember.Helper.helper(addressDisplay);
});