define('slate-payroll-admin/utils/is-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isNumber;
  function isNumber(x) {
    return typeof x === 'number' && !isNaN(x);
  }
});