define('slate-payroll-admin/routes/administration/company/groups', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var company = this.modelFor('administration.company').get('id');

      return Ember.RSVP.hash({
        departments: this.store.query('department', { company: company }),
        locations: this.store.query('location', { company: company })
      });
    },


    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});