define('slate-payroll-admin/controllers/administration/company/paycodes/add', ['exports', 'slate-payroll-admin/config/app-data'], function (exports, _appData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    paycodeTypes: _appData.paycodeTypes,
    paycodeUnits: _appData.paycodeUnits,
    companyController: Ember.inject.controller('administration/company'),
    showModal: true,
    modalId: 'add-paycode-modal',
    modalTitle: '<h2><i class="fa fa-fw fa-plus"></i> Add Paycode</h2>',

    _end: function _end(err) {
      var errMsg = err && err.responseText ? err.responseText : err;

      if (errMsg && errMsg.errors) {
        errMsg = errMsg.errors.mapBy('detail').join(', ');
      }

      if (err) {
        Ember.Logger.error(err);
      }

      this.setProperties({
        paycodeError: errMsg,
        savingPaycode: false
      });
    },
    transitionToParentRoute: function transitionToParentRoute() {
      this.transitionToRoute('administration.company.paycodes', this.get('companyController.model.id'));
      this.send('refresh');
    },


    actions: {
      cancel: function cancel() {
        this.transitionToParentRoute();
      },
      savePaycode: function savePaycode() {
        var _this = this;

        var paycode = this.get('model');
        var end = this._end.bind(this);

        end();
        this.set('savingPaycode', true);

        paycode.save().then(function () {
          end();
          _this.transitionToParentRoute();
        }).catch(end);
      }
    }
  });
});