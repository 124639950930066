define('slate-payroll-admin/components/ui-dropin-edit-employee', ['exports', 'slate-payroll-admin/config/app-data'], function (exports, _appData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['dropin-ui'],
    supervised: Ember.A(),
    locations: Ember.A(),
    departments: Ember.A(),
    showSsn: false,

    // Statics
    suffixes: _appData.suffixes,
    genders: _appData.genders,
    addressStates: _appData.states,
    filingStatuses: _appData.filingStatuses,
    maritalStatuses: _appData.maritalStatuses,
    bankAccounts: _appData.bankAccountTypes,
    payTypes: ['Hourly', 'Salary'],
    achAmountTypes: ['$', '%'],

    filteredEmployees: Ember.computed.filter('employees', function (employee) {
      return this.get('employee') !== employee;
    }),

    ssnInputType: Ember.computed('showSsn', function () {
      return this.get('showSsn') ? 'text' : 'password';
    }),

    _setPayRate: Ember.observer('payRate', 'employee.payType', function () {
      this.get('employee').setProperties({
        payRateHourly: undefined,
        payRateSalary: undefined
      });
      this.set('employee.payRate' + this.get('employee.payType'), this.get('payRate'));
    }),

    actions: {
      submit: function submit() {
        var supervised = this.get('supervised');

        if (supervised && supervised.length > 0) {
          this.get('employee.supervises').addObjects(supervised);
          this.set('supervised', Ember.A());
        }
        this.get('action')();
      },
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },
      addPendingBankAccount: function addPendingBankAccount() {
        this.set('pendingBankAccount', this.get('store').createRecord('bank-account'));
      },
      deletePendingBankAccount: function deletePendingBankAccount() {
        this.get('pendingBankAccount').destroyRecord();
        this.set('pendingBankAccount', undefined);
      },
      removeBankAccount: function removeBankAccount(bankAccount) {
        bankAccount.destroyRecord();
      },
      addBankAccount: function addBankAccount(bankAccount) {
        var model = this.get('employee');

        bankAccount.set('employee', model);
        model.get('bankAccounts').addObject(bankAccount);
        this.set('pendingBankAccount', undefined);
      }
    }
  });
});