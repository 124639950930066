define('slate-payroll-admin/models/change-request', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    diff: attr('array'),
    applied: attr('boolean'),
    isNewDocument: attr('boolean'),
    updater: attr('string'),
    rehire: attr('boolean'),

    employee: _emberData.default.belongsTo('employee', { inverse: false, async: true }),
    company: _emberData.default.belongsTo('company', { inverse: false, async: true }),
    user: _emberData.default.belongsTo('user', { inverse: false, async: true }),

    consumed: attr('date'),
    effective: attr('date'),
    created: attr('date'),
    memo: attr('string'),

    snapshot: attr() // passthru
  });
});