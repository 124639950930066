define('slate-payroll-admin/components/calendar-widget', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['calendar-widget'],

    _rowData: Ember.computed('days', function () {
      var days = this.get('days');

      if (!days) {
        return;
      }

      var ret = Ember.A(),
          rows = days.get('length') / 7;

      for (var i = 0; i < rows; i++) {
        var increment = i * 7;
        ret.addObject(days.slice(increment, increment + 7));
      }

      return ret;
    }),

    actions: {
      select: function select(day) {
        this.get('action')(day);
      }
    }
  });
});