define('slate-payroll-admin/mixins/disable-holidays', ['exports', 'slate-payroll-admin/config/app-data', 'moment'], function (exports, _appData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var holidaysAsStr = _appData.holidays.map(function (holiday) {
    return (0, _moment.default)(holiday).format('M/D/YY');
  });

  exports.default = Ember.Mixin.create({
    disableHolidays: Ember.computed(function () {
      return [function (date) {
        var dateM = (0, _moment.default)(date),
            day = dateM.day();

        return day === 0 || day === 6 || holidaysAsStr.includes(dateM.format('M/D/YY'));
      }];
    })
  });
});