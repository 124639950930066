define('slate-payroll-admin/components/review-static-column', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    classNames: ['reviewCol'],
    classNameBindings: ['isChanged:review__col-changed'],

    isChanged: Ember.computed('columnIndex', 'changes.[]', function () {
      var changes = this.get('changes');
      return changes ? changes.indexOf(this.get('columnIndex')) > -1 : false;
    }),

    isDollars: Ember.computed('headers.@each.unit', 'columnIndex', function () {
      var column = this.get('columnIndex'),
          headers = this.get('headers');

      return headers[column].unit === 'Dollars' ? true : false;
    })
  });
});