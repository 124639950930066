define('slate-payroll-admin/components/tag-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.initTags();
    },


    initTags: function initTags() {
      var selection = this.get('selection');
      this.set('tags', selection ? selection : Ember.A());
    },

    tagsChanged: Ember.observer('tags.[]', function () {
      this.get('onChange')(this.get('tags'));
    }),

    actions: {
      addTag: function addTag() {
        this.get('tags').addObject(this.get('tag'));
      },
      removeTag: function removeTag(tag) {
        this.get('tags').removeObject(tag);
      }
    }
  });
});