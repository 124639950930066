define('slate-payroll-admin/controllers/administration/data-explorer/index/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    loadingData: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.generateData();
    },


    generateData: Ember.observer('model.@each.id', function () {
      var _this = this;

      var payPeriods = this.get('model'),
          data = { nodes: [], links: [] };

      if (!payPeriods) {
        return;
      }

      this.set('loadingData', true);

      var promises = payPeriods.map(function (payPeriod) {
        return _this.store.query('punch', { payPeriod: payPeriod.get('id') }).then(function (punches) {
          return Ember.RSVP.all(punches.map(function (punch) {
            return Ember.RSVP.resolve(punch.get('employee')).then(function () {
              return punch.get('companyPaycode');
            });
          })).then(function () {
            payPeriod.set('punches', punches);
            return payPeriod;
          });
        });
      });

      Ember.RSVP.all(promises).then(function (populatedPayPeriods) {
        populatedPayPeriods.forEach(function (payPeriod) {
          data.nodes.push({
            name: payPeriod.get('id'),
            text: moment(payPeriod.get('paidOn')).format('M/D/YY'),
            size: 15,
            class: 'x-pay-period'
          });

          payPeriod.punches.forEach(function (punch) {
            var companyPaycode = punch.get('companyPaycode');

            data.nodes.push({
              name: punch.get('id'),
              text: (companyPaycode.get('paycode.description') || 'N/A') + ' ' + moment(punch.get('created')).format('M/D h:mma'),
              size: 3,
              class: 'x-punch'
            });

            data.links.push({
              source: punch.get('id'),
              target: payPeriod.get('id')
            });

            var employee = punch.get('employee');

            data.nodes.push({
              name: employee.get('id'),
              text: employee.get('fullName'),
              size: 10,
              class: 'x-employee'
            });

            data.links.push({
              source: employee.get('id'),
              target: punch.get('id')
            });
          });
        });

        _this.setProperties({
          loadingData: false,
          data: data
        });
      });
    })
  });
});