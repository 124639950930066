define('slate-payroll-admin/views/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.View.extend({
    didInsertElement: function didInsertElement() {
      this._initScrollReveal();
    },


    _initScrollReveal: function _initScrollReveal() {
      window.sr = new scrollReveal();
    }
  });
});