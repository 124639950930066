define('slate-payroll-admin/controllers/administration/change-legacy/diff', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['field', 'asc'],
    field: undefined,
    asc: false,

    actions: {
      toggleAsc: function toggleAsc() {
        this.toggleProperty('asc');
      }
    }
  });
});