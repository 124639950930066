define('slate-payroll-admin/initializers/register-modules', ['exports', 'slate-payroll-admin/modules/session'], function (exports, _session) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = undefined;
  var initialize = exports.initialize = function initialize(application) {
    //console.debug('Init :: Registering modules');
    application.register('modules:session', _session.default.extend(), { singleton: true });
  }; /*
       Use this to register custom modules
     */
  exports.default = {
    name: 'register-modules',
    after: 'inject-store-to-modules',

    initialize: initialize
  };
});