define('slate-payroll-admin/controllers/administration/changes-legacy', ['exports', 'slate-payroll-admin/mixins/resource-pagination'], function (exports, _resourcePagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_resourcePagination.default, {
    queryParams: ['page', 'company', 'employee', 'consumed', 'applied', 'sortCreatedAsc'],
    page: 1,
    limit: 15,
    applied: null,
    consumed: false,
    sortCreatedAsc: false,

    init: function init() {
      this._super.apply(this, arguments);
      this._shouldSetCompanies();
      this._shouldSetEmployees();
    },


    _appliedDidChange: Ember.observer('filterByApplied', '_applied', function () {
      this.set('applied', this.get('filterByApplied') ? !!this.get('_applied') : null);
    }),

    _employeesDidChange: Ember.observer('employees', function () {
      var employeeId = this.get('employee');

      if (employeeId) {
        this.set('employeeSelection', this.get('employees').findBy('id', employeeId));
      }
    }),

    _companiesDidChange: Ember.observer('companies', function () {
      var companyId = this.get('company');

      if (companyId) {
        this.set('companySelection', this.get('companies').findBy('id', companyId));
      }
    }),

    _shouldSetCompanies: function _shouldSetCompanies() {
      var self = this;

      this.store.query('company', { select: '_id name' }).then(function (res) {
        self.set('companies', res.get('content'));
      });
    },


    _shouldSetEmployees: Ember.observer('company', function () {
      var self = this,
          companyId = this.get('company');

      this.set('employees', null);

      if (!companyId) {
        return;
      }

      Ember.$.getJSON('/admin-api/employees', { select: '_id name.first', company: companyId }).then(function (res) {
        self.set('employees', res.employee);
      });
    }),

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },
      resetFilter: function resetFilter(filter) {
        this.set(filter, undefined);
        this.set(filter + 'Selection', undefined);
      }
    }
  });
});