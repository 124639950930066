define('slate-payroll-admin/controllers/administration/change-legacy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    _end: function _end(err, success) {
      var errMsg = err && err.responseText ? err.responseText : err;

      if (err) {
        console.error(err);
      }

      this.setProperties({
        markError: errMsg,
        marking: false,
        markSuccess: success
      });
    },


    actions: {
      markReviewed: function markReviewed() {
        var self = this;
        var change = this.get('model');

        if (this.get('marking') || change.get('consumed') || !confirm('Are you sure you want to mark this change as reviewed?')) {
          return;
        }

        this._end();

        change.set('consumed', new Date());

        change.save().then(function () {
          self._end(null, 'Successfully marked change as reviewed.');
        }).catch(this._end.bind(this));
      }
    }
  });
});