define('slate-payroll-admin/components/lazy-model-select-filter', ['exports', 'slate-payroll-admin/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LazyModelSelectComponent = Ember.Component.extend(_uxAjaxStatusHooks.default, {
    classNames: ['lazy-model-select-component'],
    classNameBindings: ['multi:lazy-model-multi-select'],
    fullSelection: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.setupComponent();
      this.instanceSelectionChanged();
    },
    didInsertElement: function didInsertElement() {
      this.resolveModel();
    },


    setupComponent: function setupComponent() {
      this.set('fullSelection', Ember.A());
    },

    resolveModel: Ember.observer('modelName', function () {
      var _this = this;

      var select = 'id',
          selectors = ['optionLabelPath', 'optionValuePath', 'selectQuery'];

      this.ajaxStart();

      selectors.forEach(function (selector) {
        if (_this.get(selector)) {
          select += ' ' + _this.get(selector);
        }
      });

      return this.store.query(this.get('modelName'), { select: select }).then(function (results) {
        _this.set('resolvedModel', results);
        _this.ajaxSuccess();
      }).catch(this.ajaxError.bind(this));
    }),

    instanceSelectionChanged: Ember.observer('resolvedModel', 'selection', function () {
      if (!this.get('resolvedModel')) {
        return;
      }

      var selection = this.get('selection');

      if (this.get('multi')) {
        var model = this.get('resolvedModel'),
            valuePath = this.get('optionValuePath');

        var update = model.filter(function (item) {
          var _value = valuePath ? item.get(valuePath) : item;
          return selection.contains(_value);
        });

        this.set('fullSelection', update);
      } else {
        this.set('boundSelectSelection', selection);
      }
    }),

    actions: {
      selectionChanged: function selectionChanged(item) {
        var _this2 = this;

        var changeCallback = this.get('action'),
            isMulti = this.get('multi'),
            valuePath = this.get('optionValuePath');

        this.set('boundSelectSelection', item);

        Ember.run.next(function () {
          if (isMulti && item) {
            var objectToAdd = valuePath ? _this2.get('resolvedModel').findBy(valuePath, item) : item,
                fullSelection = _this2.get('fullSelection');

            fullSelection.addObject(objectToAdd);
            _this2.set('boundSelectSelection', undefined);
            changeCallback(valuePath ? fullSelection.mapBy(valuePath) : fullSelection.toArray());
          } else {
            changeCallback(item);
          }
        });
      },
      deselectItem: function deselectItem(item) {
        var changeCallback = this.get('action'),
            fullSelection = this.get('fullSelection'),
            valuePath = this.get('optionValuePath');

        fullSelection.removeObject(item);
        changeCallback(valuePath ? fullSelection.mapBy(valuePath) : Ember.A(fullSelection.toArray()));
      }
    }
  });

  LazyModelSelectComponent.reopenClass({
    positionalParams: ['modelName']
  });

  /*
    {{lazy-model-select 'my-model'
      store=store
      label='My Select'
      selectId='my-select-id'
      selectClass='form-control'
      optionLabelPath='model.label.path'
      selection=defaultSelection
      multi=true
      action=(action (mut mySelectionValue))
      prompt="--Select--"}}
  */

  exports.default = LazyModelSelectComponent;
});