define('slate-payroll-admin/components/paystubs/paystub-document-ach', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.transactionSorting = ['order'];
    },

    achTransactionsOrdered: Ember.computed.sort('achTransactions', 'transactionSorting')
  });
});