define('slate-payroll-admin/models/earning', ['exports', 'slate-payroll-admin/models/transaction'], function (exports, _transaction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transaction.default.extend({
    isTaxable: Ember.computed('taxLocal', 'taxState', 'taxFederal', function () {
      return this.get('taxLocal') || this.get('taxState') || this.get('taxFederal');
    })
  });
});