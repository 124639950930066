define('slate-payroll-admin/components/bound-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    content: null,
    prompt: null,
    optionValuePath: null,
    optionLabelPath: null,
    action: function action() {}, // action to fire on change
    compareSelectionWithValue: false,

    // shadow the passed-in `selection` to avoid
    // leaking changes to it via a 2-way binding
    _selection: Ember.computed.reads('selection'),

    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('content')) {
        this.set('content', []);
      }
    },


    actions: {
      change: function change() {
        var selectEl = this.$('select')[0];
        var selectedIndex = selectEl.selectedIndex;
        var content = this.get('content');

        // decrement index by 1 if we have a prompt
        var hasPrompt = !!this.get('prompt');
        var contentIndex = hasPrompt ? selectedIndex - 1 : selectedIndex;

        var optionValuePath = this.get('optionValuePath');
        var selection = content.objectAt ? content.objectAt(contentIndex) : content[contentIndex];

        // set the local, shadowed selection to avoid leaking
        // changes to `selection` out via 2-way binding
        this.set('_selection', selection);

        var changeCallback = this.get('action');
        var arg = optionValuePath && selection ? Ember.get(selection, optionValuePath) : selection;

        changeCallback(arg);
      }
    }
  });
});