define('slate-payroll-admin/routes/administration/data-explorer/index/reports/view/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var query = this.modelFor('administration.data-explorer.index.reports.view');

      return Ember.RSVP.hash({
        query: query,
        queryResults: this.store.query('query-result', { query: query.get('id'), sort: { created: -1 } })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.queryResults,
        query: model.query
      });
    }
  });
});