define('slate-payroll-admin/components/change-request-value-translator', ['exports', 'slate-payroll-admin/models/employee', 'moment'], function (exports, _employee, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var JS_FORMAT = 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'; /* eslint-disable no-undef */


  var staticRels = {
    companyPaycode: 'company-paycode'
  };

  exports.default = Ember.Component.extend({
    tagName: 'span',
    store: Ember.inject.service(),

    displayMap: {
      company: 'name',
      location: 'name',
      department: 'name',
      employee: 'fullName',
      'company-paycode': 'description'
    },

    isRelationship: Ember.computed.bool('coercionType.model'),

    displayKey: Ember.computed('displayMap', 'coercionType.model', function () {
      var model = this.get('coercionType.model');
      return model ? this.get('displayMap.' + model) : undefined;
    }),

    coercionType: Ember.computed(function () {
      var _this = this;

      var attributeKinds = Ember.get(_employee.default, 'fields'),
          changed = this.get('pathForChange'),
          transformedAttrs = Ember.get(_employee.default, 'transformedAttributes');

      var attributeField = void 0;

      attributeKinds.forEach(function (type, field) {
        if (changed === field) {
          var transform = transformedAttrs.get(field);
          attributeField = { type: type, transform: transform };

          if (attributeField.type === 'belongsTo') {
            attributeField.model = Ember.get(_employee.default.typeForRelationship(field, _this.get('store')), 'modelName');
          }
        } else if (staticRels[changed]) {
          attributeField = {
            type: 'belongsTo',
            model: staticRels[changed]
          };
        }
      });

      return attributeField;
    }),

    pathForChange: Ember.computed('key', function () {
      return this.get('key').join('.');
    }),

    computedValue: Ember.computed('coercionType', 'pathForChange', function () {
      var _getProperties = this.getProperties('value', 'coercionType'),
          value = _getProperties.value,
          coercionType = _getProperties.coercionType;

      if (!coercionType || !value) {
        return value;
      }

      if ((typeof value === 'string' || value instanceof Date) && (coercionType.transform === 'date' || (0, _moment.default)(value.replace(/ \(.*\)/, ''), JS_FORMAT, true).isValid())) {
        return (0, _moment.default)(value).format('MM/DD/YYYY');
      } else if (coercionType.type === 'belongsTo') {
        return this.get('store').findRecord(coercionType.model, value);
      } else {
        return value;
      }
    })
  });
});