define('slate-payroll-admin/routes/2fa/setup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.setProperties({
        qr: null,
        secret: null,
        selectedTfMethod: null,
        displayMethodSetup: false
      });

      this._super.apply(this, arguments);
    }
  });
});