define('slate-payroll-admin/controllers/administration/companies/add/locations', ['exports', 'slate-payroll-admin/config/app-data'], function (exports, _appData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    states: _appData.states,

    init: function init() {
      this._super.apply(this, arguments);
      this._setNewPendingLocation();
      this._setNewPendingDepartment();
    },


    hasPrimaryLocation: Ember.computed('model.locations', function () {
      var locations = this.get('model.locations');
      return locations ? locations.findBy('isPrimary', true) : undefined;
    }),

    _setNewPendingLocation: function _setNewPendingLocation() {
      this.set('pendingLocation', this.store.createRecord('location', {
        isPrimary: !this.get('hasPrimaryLocation'),
        addressState: this.get('model.addressState')
      }));
    },
    _setNewPendingDepartment: function _setNewPendingDepartment() {
      this.set('pendingDepartment', this.store.createRecord('department'));
    },


    actions: {
      nextStep: function nextStep() {
        this.transitionToRoute('administration.companies.add.paycodes');
      },
      addLocation: function addLocation(location) {
        // Set company field on location
        location.set('company', this.get('model'));
        // Add location to company record
        this.get('model.locations').addObject(location);
        // Clear form
        this._setNewPendingLocation();

        // Set the address on the company record
        // if the location is the primary
        if (location.get('isPrimary')) {
          this.get('model').setProperties(location.getProperties('addressLine1', 'addressLine2', 'addressCity', 'addressState', 'addressZipcode'));
        }
      },
      addDepartment: function addDepartment(department) {
        // Set company field on dept
        department.set('company', this.get('model'));
        // Add dept to company record
        this.get('model.departments').addObject(department);
        // Clear form
        this._setNewPendingDepartment();
      }
    }
  });
});