define('slate-payroll-admin/serializers/company-paycode', ['exports', 'ember-data', 'slate-payroll-admin/serializers/application', 'slate-payroll-admin/utils/serialize-object'], function (exports, _emberData, _application, _serializeObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,

    attrs: {
      paycode: { deserialize: 'records', serialize: 'ids' }
    },

    normalize: function normalize(modelClass, hash) {
      (0, _serializeObject.default)(hash, false, 'codeSettings', 'permissions');
      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      json.codeSettings = {
        countsTowardsSalary: json.countsTowardsSalary,
        countsTowardsOvertime: json.countsTowardsOvertime,
        isDefault: json.isDefault
      };

      delete json.countsTowardsSalary;
      delete json.countsTowardsOvertime;
      delete json.isDefault;

      json.permissions = {
        employees: json.employees,
        supervisors: json.supervisors,
        managers: json.managers,
        basic: json.basic
      };

      delete json.employees;
      delete json.supervisors;
      delete json.managers;
      delete json.basic;

      return json;
    }
  });
});