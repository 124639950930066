define('slate-payroll-admin/routes/administration/admin-users/add', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord('admin-user');
    },

    actions: {
      willTransition: function willTransition(transition) {
        var model = this.controller.get('model');

        if (!model.get('isNew')) {
          return;
        }

        if (!confirm('Are you sure you want to abandon progress on this new user?')) {
          transition.abort();
        } else {
          model.destroyRecord();
        }
      }
    }
  });
});