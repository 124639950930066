define('slate-payroll-admin/mixins/controller-abstractions/delete', ['exports', 'slate-payroll-admin/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_uxAjaxStatusHooks.default, {
    confirmDelete: false,

    actions: {
      delete: function _delete(model) {
        var _this = this;

        var _model = model || this.get('model');

        if (!_model || this.get('confirmDelete') && !confirm('Are you sure you want to delete this?')) {
          return;
        }

        this.ajaxStart();

        _model.destroyRecord().then(function () {
          _this.ajaxSuccess('Successfully deleted.');
        }).catch(this.ajaxError.bind(this));
      }
    }
  });
});