define('slate-payroll-admin/components/paystubs/paystub-document-employee-information', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['document-segmentation']
  });
});