define('slate-payroll-admin/utils/address-formatter', ['exports', 'slate-payroll-admin/utils/title-case'], function (exports, _titleCase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = addressFormatter;
  function addressFormatter() {
    var data = this.getProperties('addressLine1', 'addressLine2', 'addressCity', 'addressState', 'addressZipcode', 'hasAddress');

    if (data.hasAddress === false) {
      return '';
    }

    data.addressLine2 = data.addressLine2 ? data.addressLine2 + ' ' : '';
    data.addressZipcode = data.addressZipcode ? data.addressZipcode : '';

    return (0, _titleCase.default)(data.addressLine1 + ' ' + data.addressLine2 + data.addressCity + ', ' + data.addressState + ' ' + data.addressZipcode);
  }
});