define('slate-payroll-admin/routes/administration/help-articles/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('help-article', params.id);
    },


    actions: {
      willTransition: function willTransition() {
        var model = this.controller.get('model');

        if (model.get('hasDirtyAttributes')) {
          model.rollbackAttributes();
        }
      }
    }
  });
});