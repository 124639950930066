define('slate-payroll-admin/components/popover-add', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'a',
    classNames: ['popover-add-component'],
    attributeBindings: ['_x:href', 'title'],
    _x: '#',
    engagePopover: false,

    didInsertElement: function didInsertElement() {
      this._autoFocus();
    },
    click: function click(event) {
      event.preventDefault();
      if (event.target.tagName === 'A') {
        this.toggleProperty('engagePopover');
      }
    },


    _autoFocus: Ember.observer('engagePopover', function () {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        var $input = Ember.$('#' + _this.get('elementId') + ' input')[0];

        if ($input) {
          $input.focus();
        }
      });
    }),

    _closePopover: Ember.observer('closePopover', function () {
      if (this.get('closePopover')) {
        this.setProperties({
          engagePopover: false,
          closePopover: false
        });
      }
    })
  });
});