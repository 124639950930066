define('slate-payroll-admin/controllers/administration/companies/add/users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    users: Ember.computed.alias('model.users'),

    actions: {
      nextStep: function nextStep() {
        this.transitionToRoute('administration.companies.add.complete');
      },
      initNewUserModel: function initNewUserModel() {
        this.set('pendingUser', this.store.createRecord('user', {
          company: this.get('model')
        }));
      },
      addUser: function addUser() {
        this.get('model.users').addObject(this.get('pendingUser'));
        this.set('pendingUser', undefined);
      },
      destroyUser: function destroyUser(user) {
        user.destroyRecord();
      },
      cancelAdd: function cancelAdd() {
        this.get('pendingUser').destroyRecord();
        this.set('pendingUser', undefined);
      }
    }
  });
});