define('slate-payroll-admin/components/data-explorer/limit-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    updateValue: Ember.observer('limitNumber', 'limitCreationDate', 'limitByNumber', 'limitByCreationDate', function () {
      var dataPoints = this.get('dataPoints');

      if (this.get('limitByNumber')) {
        this.set('limitItems', this.get('limitNumber'));
      }

      var creationDateDataPoint = dataPoints.findBy('limiter', true);
      if (creationDateDataPoint) {
        creationDateDataPoint.destroyRecord();
      }

      if (this.get('limitByCreationDate') && this.get('limitCreationDate')) {
        dataPoints.addObject(this.store.createRecord('data-point', {
          operator: 'is within',
          value: this.get('limitCreationDate'),
          isRelationship: false,
          isDate: true,
          propertyName: 'Created',
          propertyKey: 'created',
          propertyType: 'attribute',
          limiter: true
        }));
      }
    })
  });
});