define('slate-payroll-admin/controllers/administration/companies/add/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    logoFileExtensions: ['jpg', 'jpeg', 'png', 'gif', 'tif'],

    _autoCorrectUrlKey: Ember.observer('model.urlKey', function () {
      var urlKey = this.get('model.urlKey');

      if (!urlKey) {
        return;
      }

      this.set('model.urlKey', urlKey.replace(/[^a-z]/gi, '').toLowerCase());
    }),

    _urlKeyCheck: function _urlKeyCheck() {
      var self = this;

      this.setProperties({
        __urlKeyCheckEvent: null
      });

      Ember.$.getJSON('/api/company/exists/' + this.get('model.urlKey'), function (res) {
        self.setProperties({
          checkingUrlKey: false,
          didCheckUrlKey: true,
          urlKeyTaken: res.exists
        });
      });
    },

    _shouldQueueUrlKeyCheck: Ember.observer('model.urlKey', function () {
      this.set('didCheckUrlKey', false);

      if (this.get('__urlKeyCheckEvent')) {
        Ember.run.cancel(this.get('__urlKeyCheckEvent'));
      }

      if (!this.get('model.urlKey')) {
        return this.set('checkingUrlKey', false);
      }

      var scheduled = Ember.run.later(this, this._urlKeyCheck, 800);
      this.setProperties({
        checkingUrlKey: true,
        __urlKeyCheckEvent: scheduled
      });
    }),

    actions: {
      nextStep: function nextStep() {
        this.transitionToRoute('administration.companies.add.settings');
      }
    }
  });
});