define('slate-payroll-admin/routes/administration/company/employees', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OMIT_SERIALIZED_KEYS = ['page', 'limit', 'select', 'skip'];

  exports.default = Ember.Route.extend({
    model: function model() {
      var query = {
        company: this.modelFor('administration.company').get('id'),
        select: 'name time_stamp',
        sort: {
          'name.last': 1,
          'name.first': 1
        }
      };

      this.set('_serializedQuery', this.getSerializedQuery(query));

      return this.store.query('employee', query);
    },
    getSerializedQuery: function getSerializedQuery(q) {
      var _q = Object.assign({}, q);

      OMIT_SERIALIZED_KEYS.forEach(function (k) {
        delete _q[k];
      });

      return JSON.stringify(_q);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('serializedQuery', this._serializedQuery);
    }
  });
});