define('slate-payroll-admin/controllers/administration/companies/add/settings', ['exports', 'slate-payroll-admin/config/app-data'], function (exports, _appData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    payPeriodFrequencies: _appData.payPeriodFrequencies,
    workDays: _appData.workDays,

    actions: {
      nextStep: function nextStep() {
        this.transitionToRoute('administration.companies.add.locations');
      },
      addEmail: function addEmail(email) {
        // Add the email to the array
        this.get('model.globalNotificationEmails').addObject(email);
        // Change the input value for UX like:
        // test@example.com -> @example.com
        this.set('pendingEmail', email.replace(/.*@/, '@'));
        // We have to run the following code in
        // the next tick to avoid a race condition:
        // Set pendingEmail -> Set selection/Input val update at the same time
        Ember.run.next(this, function () {
          // Grab the input
          var input = document.getElementById('company-notifications');
          input.focus();
          // Check input methods, use proper one to move the
          // cursor to the beginning of the input
          if (input.createTextRange) {
            var r = input.createTextRange();
            r.move('character', 0);
            r.select();
          } else if (input.setSelectionRange) {
            input.setSelectionRange(0, 0);
          }
        });
      }
    }
  });
});