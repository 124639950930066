define('slate-payroll-admin/controllers/administration/help-articles/view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      deleteArticle: function deleteArticle() {
        var _this = this;

        var article = this.get('model');
        article.destroyRecord().then(function () {
          _this.transitionToRoute('administration.help-articles');
        });
      }
    }
  });
});