define('slate-payroll-admin/routes/administration/company/user/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        model: this.modelFor('administration.company.user').users,
        permissions: this.store.findAll('permission'),
        employees: this.store.query('employee', {
          company: this.paramsFor('administration.company').id,
          sort: {
            'name.last': 1,
            'name.first': 1
          }
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.model,
        permissions: model.permissions,
        employees: model.employees
      });
    }
  });
});