define('slate-payroll-admin/controllers/administration/companies/add/employees', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    employees: Ember.computed.alias('model.employees'),

    actions: {
      nextStep: function nextStep() {
        this.transitionToRoute('administration.companies.add.users');
      },
      initNewEmployeeModel: function initNewEmployeeModel() {
        this.set('pendingEmployee', this.store.createRecord('employee', {
          company: this.get('model')
        }));
      },
      addEmployee: function addEmployee() {
        this.get('model.employees').addObject(this.get('pendingEmployee'));
        this.set('pendingEmployee', undefined);
      },
      destroyEmployee: function destroyEmployee(employee) {
        employee.destroyRecord();
      },
      cancelAdd: function cancelAdd() {
        this.get('pendingEmployee').destroyRecord();
        this.set('pendingEmployee', undefined);
      }
    }
  });
});