define('slate-payroll-admin/models/transaction', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    code: attr('string'),
    description: attr('string'),
    units: attr('number'),
    rate: attr('number'),
    total: attr('number'),

    taxLocal: attr('boolean'),
    taxState: attr('boolean'),
    taxFederal: attr('boolean'),
    taxStateWithholding: attr('string'),
    taxStateUnemployment: attr('string'),

    companyPaycode: _emberData.default.belongsTo('company-paycode', {
      async: true,
      inverse: false
    })
  });
});