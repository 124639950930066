define('slate-payroll-admin/models/company', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    name: attr('string'),
    urlKey: attr('string'),
    tin: attr('string'),
    email: attr('string'),
    logo: attr('string'),

    addressLine1: attr('string'),
    addressLine2: attr('string'),
    addressCity: attr('string'),
    addressState: attr('string', { defaultValue: 'MT' }),
    addressZipcode: attr('string'),
    faxNumbers: attr('array'),
    devices: attr('array'),

    loginMessage: attr('string'),
    payPeriod: attr('string', { defaultValue: 'Semi-Monthly' }),
    maxHourEntry: attr('number', { defaultValue: 16 }),
    workWeekStart: attr('number', { defaultValue: 0 }),
    timeMinuteInterval: attr('number', { defaultValue: 15 }),
    requireSignatures: attr('boolean', { defaultValue: true }),
    useGeolocation: attr('boolean', { defaultValue: true }),
    enableSquares: attr('boolean', { defaultValue: false }),
    requireSquares: attr('boolean', { defaultValue: false }),
    landOnSquaresLogin: attr('boolean', { defaultValue: false }),
    timeOfDayTimeOff: attr('boolean', { defaultValue: false }),
    sentWelcomeEmails: attr('boolean'),
    tz: attr('string', { defaultValue: 'America/Denver' }),
    codeBasedPunch: attr('boolean', { defaultValue: false }),

    incompletePunches: attr('boolean'),

    supervisorChanges: attr('boolean'),
    supervisorReportsOnly: attr('boolean'),

    ipAddresses: attr('string'),

    // Email Settings
    incompletePunchNotificationEmails: attr('array'),
    globalNotificationEmails: attr('array'),

    // Relational
    employees: _emberData.default.hasMany('employee', { async: true }),
    departments: _emberData.default.hasMany('department', { async: true }),
    locations: _emberData.default.hasMany('location', { async: true }),
    companyPaycodes: _emberData.default.hasMany('company-paycode', { async: true }),
    payPeriods: _emberData.default.hasMany('pay-period', { async: true }),
    users: _emberData.default.hasMany('user', { async: true }),
    overtimeRules: _emberData.default.hasMany('overtime-rule'),

    // Legacy Fields
    payrollDbId: attr('number'),
    legacyCompanyId: attr('number'),
    legacyUsernamePrefix: attr('string'),
    legacyMaxEFileMonths: attr('number'),

    inactiveOn: attr('date'),
    'time_stamp': attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),

    payPeriodIsSemiMonthly: Ember.computed.equal('payPeriod', 'Semi-Monthly')
  });
});