define('slate-payroll-admin/controllers/administration/company/pay-periods/html-report', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    companyController: Ember.inject.controller('administration/company'),
    showModal: true,
    modalId: 'pay-period-report-modal',
    modalTitle: '<h2>Pay Period HTML Report</h2>',

    fixedChanges: Ember.computed('model.{statics,staticHeaders}', function () {
      var rows = this.get('model.statics'),
          allHeaders = this.get('model.staticHeaders'),
          columnsChanged = Ember.A(),
          headers = void 0;

      var changesInclude = function changesInclude(item, i) {
        return columnsChanged.includes(i);
      };

      if (rows) {
        rows.forEach(function (row) {
          if (!row.change) {
            return;
          }

          columnsChanged.addObjects(row.changesTo);
        });
      }

      if (allHeaders) {
        headers = allHeaders.filter(changesInclude);
      }

      return rows && headers ? {
        headers: headers,
        rows: rows.filter(function (row) {
          return row.change;
        }).map(function (row) {
          var columns = row.columns.filter(changesInclude);
          var changesTo = row.changesTo.map(function (changeIndex) {
            var header = allHeaders[changeIndex];

            if (headers.includes(header)) {
              return headers.indexOf(header);
            }
          });

          return Ember.$.extend({}, row, { columns: columns, changesTo: changesTo });
        })
      } : undefined;
    }),

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },
      close: function close() {
        this.transitionToRoute('administration.company.pay-periods.index', this.get('companyController.model.id'));
      }
    }
  });
});