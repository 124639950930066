define('slate-payroll-admin/serializers/location', ['exports', 'slate-payroll-admin/serializers/application', 'ember-data', 'slate-payroll-admin/utils/serialize-object'], function (exports, _application, _emberData, _serializeObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    normalize: function normalize(modelClass, hash) {
      (0, _serializeObject.default)(hash, true, 'address');
      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      json.address = {
        line1: json.addressLine1,
        line2: json.addressLine2,
        city: json.addressCity,
        state: json.addressState,
        zipcode: json.addressZipcode
      };

      delete json.addressLine1;
      delete json.addressLine2;
      delete json.addressCity;
      delete json.addressState;
      delete json.addressZipcode;

      return json;
    }
  });
});