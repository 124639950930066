define('slate-payroll-admin/controllers/administration/companies/add/overtime-rules', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    completeController: Ember.inject.controller('administration/companies/add/complete'),
    overtimeRules: Ember.computed.alias('completeController.overtimeRules'),
    formValid: Ember.computed.and('pendingRule.hours', 'pendingRule.measure', 'pendingRuleHasPaycode'),
    pendingRuleHasPaycode: Ember.computed.bool('pendingRule.paycode.paycode.description'),

    otMeasures: ['Week', 'Day'],

    init: function init() {
      this._super.apply(this, arguments);
      this._setNewPendingRule();
    },
    _setNewPendingRule: function _setNewPendingRule() {
      this.set('pendingRule', this.store.createRecord('overtime-rule'));
    },


    actions: {
      nextStep: function nextStep() {
        this.transitionToRoute('administration.companies.add.employees');
      },
      addRule: function addRule(rule) {
        // Add rule to company record
        this.get('overtimeRules').addObject(rule);
        // Clear form
        this._setNewPendingRule();
      },
      deleteRule: function deleteRule(rule) {
        this.get('overtimeRules').removeObject(rule);
        rule.destroyRecord();
      }
    }
  });
});