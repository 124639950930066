define('slate-payroll-admin/controllers/administration/companies/add/paycodes', ['exports', 'slate-payroll-admin/config/app-data', 'slate-payroll-admin/mixins/resource-pagination'], function (exports, _appData, _resourcePagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_resourcePagination.default, {
    paycodeTypes: _appData.paycodeTypes,
    paycodeUnits: _appData.paycodeUnits,
    needs: ['administration/companies/add'],
    queryParams: ['page', 'description'],
    page: 1,
    limit: 50,
    description: undefined,
    selectedPaycodes: Ember.A(),
    newCompanyController: Ember.inject.controller('administration/companies/add'),
    company: Ember.computed.alias('newCompanyController.model'),

    init: function init() {
      this._super.apply(this, arguments);
      this._setSearchValue();
    },


    _setSearchValue: function _setSearchValue() {
      Ember.run.next(this, function () {
        var description = this.get('description');

        if (description) {
          this.set('searchValue', description);
        }
      });
    },

    actions: {
      nextStep: function nextStep() {
        this.transitionToRoute('administration.companies.add.overtime-rules');
      },
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },
      selectAll: function selectAll() {
        this.get('selectedPaycodes').addObjects(this.get('model'));
      },
      deselectAll: function deselectAll() {
        this.set('selectedPaycodes', Ember.A());
      },
      clearSearchText: function clearSearchText() {
        this.setProperties({
          searchValue: undefined,
          description: undefined
        });
      },
      setSearch: function setSearch() {
        this.set('description', this.get('searchValue'));
      },
      clearQueue: function clearQueue() {
        this.set('queue', null);
      },
      cancelQueue: function cancelQueue() {
        this.get('queue').forEach(function (model) {
          model.destroyRecord();
        });

        this.set('queue', Ember.A());
      },
      queuePaycodes: function queuePaycodes() {
        var self = this,
            toQueue = this.get('selectedPaycodes'),
            company = this.get('company');

        this.send('deselectAll');

        var queue = toQueue.map(function (paycode) {
          return self.store.createRecord('company-paycode', {
            paycode: paycode,
            company: company
          });
        });

        this.set('queue', queue);
      },
      addPaycodes: function addPaycodes() {
        // Grab the queue
        var paycodes = this.get('queue');
        // Add the paycodes to the company
        this.get('company.companyPaycodes').addObjects(paycodes);
        // Deselect all of the selected paycodes & clear queue
        this.send('deselectAll');
        this.send('clearQueue');
      },
      removePaycode: function removePaycode(record) {
        record.destroyRecord();
      }
    }
  });
});