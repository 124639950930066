define('slate-payroll-admin/components/x-model-count-display', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['model-count'],

    didInsertElement: function didInsertElement() {
      this._getCount();
    },


    hasCount: Ember.computed('__count', function () {
      var count = this.get('__count');
      return count !== undefined && !isNaN(count);
    }),

    _getCount: Ember.observer('count', function () {
      var stat = this.get('count'),
          self = this;

      var _handleError = function _handleError(err) {
        self.set('__error', err && err.responseText ? err.responseText : err);
      };

      Ember.$.getJSON('/admin-api/' + stat, { _count: true }, function (res) {
        self.set('__count', res.count);
      }).fail(_handleError);
    }),

    capitalized: Ember.computed('count', function () {
      return Ember.String.capitalize(this.get('count'));
    })
  });
});