define('slate-payroll-admin/components/diff-value-translator', ['exports', 'slate-payroll-admin/models/employee', 'moment'], function (exports, _employee, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    displayMap: {
      company: 'name',
      location: 'name',
      department: 'name',
      employee: 'fullName',
      user: 'fullName'
    },
    store: Ember.inject.service(),

    isRelationship: Ember.computed.bool('coercionType.model'),

    displayKey: Ember.computed('displayMap', 'coercionType.model', function () {
      var model = this.get('coercionType.model');
      return model ? this.get('displayMap.' + model) : undefined;
    }),

    coercionType: Ember.computed(function () {
      var _this = this;

      var attributeKinds = Ember.get(_employee.default, 'fields'),
          changed = this.get('pathForChange'),
          transformedAttrs = Ember.get(_employee.default, 'transformedAttributes');

      var coercion = void 0;

      attributeKinds.forEach(function (type, field) {
        if (changed === field) {
          var transform = transformedAttrs.get(field);
          coercion = { type: type, transform: transform };
          if (coercion.type === 'belongsTo') {
            coercion.model = Ember.get(_employee.default.typeForRelationship(field, _this.get('store')), 'modelName');
          }
        }
      });

      return coercion;
    }),

    pathForChange: Ember.computed('key', function () {
      var changed = this.get('key');
      return changed.join('.');
    }),

    computedValue: Ember.computed('coercionType', 'pathForChange', function () {
      var _getProperties = this.getProperties('value', 'coercionType'),
          value = _getProperties.value,
          coercionType = _getProperties.coercionType;

      if (!coercionType || !value) {
        return value;
      }

      if (coercionType.transform === 'date') {
        return (0, _moment.default)(value).format('M/D/YYYY');
      } else if (coercionType.type === 'belongsTo') {
        return this.get('store').findRecord(coercionType.model, value);
      } else {
        return value;
      }
    })
  });
});