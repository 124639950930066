define('slate-payroll-admin/controllers/administration/help-articles/index', ['exports', 'slate-payroll-admin/mixins/resource-pagination', 'slate-payroll-admin/utils/title-case'], function (exports, _resourcePagination, _titleCase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_resourcePagination.default, {
    queryParams: ['page', 'sortAsc', 'showFilters', 'sortBy', 'filterCategory'],
    sortBy: 'created',
    sortAsc: false,
    page: 1,
    limit: 20,
    showFilters: false,
    filterCategory: Ember.A(),

    _sortOptions: [{
      label: 'Created',
      value: 'created'
    }, {
      label: 'Category',
      value: 'category'
    }, {
      label: 'Article Title',
      value: 'title'
    }],

    sortOptions: Ember.computed('_sortOptions', function () {
      var sortOptions = this.get('_sortOptions');
      return sortOptions.map(function (option) {
        return typeof option === 'string' ? { label: (0, _titleCase.default)([option]), value: option } : option;
      });
    }),

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      }
    }
  });
});