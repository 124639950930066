define('slate-payroll-admin/routes/administration/data-explorer', ['exports', 'slate-payroll-admin/mixins/routable-modal'], function (exports, _routableModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routableModal.default, {
    model: function model() {
      return this.session.get('currentUser');
    },


    actions: {
      willTransition: function willTransition(transition) {
        var controller = this.controller,
            intent = transition.intent,
            intentName = intent.name || intent.url;

        // If the model is hidden or if the transition intent is within the route
        if (!controller.get('showModal') || intentName.indexOf('explore') > -1) {
          return;
        }

        transition.abort();
        controller.set('showModal', false);

        this.awaitModalClose().then(function () {
          return transition.retry();
        });
      }
    }
  });
});