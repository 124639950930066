define('slate-payroll-admin/components/data-explorer/data-point-display', ['exports', 'slate-payroll-admin/mixins/render-tooltips'], function (exports, _renderTooltips) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_renderTooltips.default, {
    operatorGroups: Ember.A(),
    filteredDataPoints: Ember.computed.filterBy('dataPoints', 'queryGroup', undefined),

    rerenderTooltips: Ember.observer('filteredDataPoints.[]', function () {
      this._renderTooltips();
    }),

    actions: {
      addGroup: function addGroup(operator) {
        var groups = this.get('operatorGroups');
        var groupRecord = this.store.createRecord('query-group', {
          id: groups.get('length') + 1,
          type: operator
        });

        groupRecord.set(operator, true);
        groups.addObject(groupRecord);
      },
      deleteGroup: function deleteGroup(group) {
        // Reset operator group on data points
        this.get('dataPoints').filterBy('queryGroup', group.get('id')).toArray().forEach(function (dataPoint) {
          Ember.set(dataPoint, 'queryGroup', undefined);
        });
        // Remove group
        group.destroyRecord();
        group.unloadRecord();
      },
      deleteDataPoint: function deleteDataPoint(dataPoint) {
        dataPoint.destroyRecord();
        this.get('dataPoints').removeObject(dataPoint);
      }
    }
  });
});