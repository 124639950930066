define('slate-payroll-admin/components/ui-dropin-edit-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['dropin-ui'],
    sortPermissions: ['prettyName'],
    sortedPermissions: Ember.computed.sort('permissions', 'sortPermissions'),

    _usernameCheck: function _usernameCheck() {
      var self = this;

      this.setProperties({
        __usernameCheckEvent: null
      });

      Ember.$.getJSON('/api/user/exists/' + this.get('user.username'), function (res) {
        self.setProperties({
          checkingUsername: false,
          didCheckUsername: true,
          usernameTaken: res.exists
        });
      });
    },

    _shouldQueueUsernameCheck: Ember.observer('user.username', function () {
      this.set('didCheckUsername', false);

      if (this.get('__usernameCheckEvent')) {
        Ember.run.cancel(this.get('__usernameCheckEvent'));
      }

      if (!this.get('user.username')) {
        return this.set('checkingUsername', false);
      }

      var scheduled = Ember.run.later(this, this._usernameCheck, 800);
      this.setProperties({
        checkingUsername: true,
        __usernameCheckEvent: scheduled
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this._getPermissions();
    },
    _getPermissions: function _getPermissions() {
      var self = this;

      this.get('store').findAll('permission').then(function (permissions) {
        self.set('permissions', permissions);
      });
    },


    actions: {
      submit: function submit() {
        this.get('action')();
      },
      selectAll: function selectAll() {
        this.get('user.permissions').addObjects(this.get('permissions'));
      },
      deselectAll: function deselectAll() {
        this.get('user.permissions').clear();
      }
    }
  });
});