define('slate-payroll-admin/routes/administration/data-explorer/index/reports/view/result', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        queryResult: this.store.find('query-result', params.resultid),
        query: this.modelFor('administration.data-explorer.index.reports.view')
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.queryResult,
        query: model.query
      });
    }
  });
});