define('slate-payroll-admin/models/company-paycode', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    type: attr('string', { defaultValue: 'E' }),
    format: attr('string'),
    unit: attr('string'),
    unitActual: attr('string'),
    sort: attr('number'),
    timeOffCapability: attr('boolean'),
    codeBasedPunchCapability: attr('boolean'),
    static: attr('boolean'),
    description: attr('string'),
    deprecated: attr('boolean'),

    crossReference: attr('string'),
    altId: attr('string'),

    countsTowardsSalary: attr('boolean'),
    countsTowardsOvertime: attr('boolean'),
    isDefault: attr('boolean'),

    employees: attr('boolean'),
    supervisors: attr('boolean'),
    managers: attr('boolean'),
    basic: attr('boolean'),

    // Relational
    company: _emberData.default.belongsTo('company', { async: true }),
    paycode: _emberData.default.belongsTo('paycode', { async: false, inverse: false }),

    // Legacy
    legacyCompanyPaycodeId: attr('number'),

    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });
});