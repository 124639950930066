define('slate-payroll-admin/controllers/administration/changes/company', ['exports', 'slate-payroll-admin/config/models', 'slate-payroll-admin/mixins/ux-ajax-status-hooks'], function (exports, _models, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var isNully = function isNully(v) {
    return v === undefined || v === null || v === '' || v === [];
  };

  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    changeGroups: Ember.computed('model.requests.@each.employee', function () {
      return this.get('model.requests').reduce(function (groups, request) {
        var employee = request.get('employee');

        if (!groups.findBy('employee.id', employee.get('id'))) {
          groups.addObject({
            employee: employee,
            requests: Ember.A()
          });
        }

        groups.findBy('employee.id', employee.get('id')).requests.addObject(request);

        return groups;
      }, Ember.A()).map(function (group) {
        return Object.assign(group, {
          includesNewHire: group.requests.find(function (req) {
            return req.isNewDocument;
          }),
          includesRehire: group.requests.find(function (req) {
            return req.rehire;
          }),
          requests: group.requests.map(function (req) {
            req.set('diff', (req.diff || []).reduce(function (diffs, diff) {
              // Exclude really similar null values
              if (diff.kind !== 'A' && isNully(diff.lhs) && isNully(diff.rhs)) {
                return diffs;
              }

              var shallowDiffPath = (diff.path || [])[0];

              // Resume reducing, we are only touching array diffs
              if (diff.kind !== 'A' && _models.employeeModelArrayPaths.indexOf((diff.path || [])[0]) < 0) {
                diffs.addObject(diff);
                return diffs;
              }

              var similarDiff = diffs.find(function (d) {
                return shallowDiffPath === (d.path || [])[0];
              });

              if (similarDiff) {
                similarDiff.relatedDiffs = similarDiff.relatedDiffs ? [diff].concat(_toConsumableArray(similarDiff.relatedDiffs)) : [diff];
              } else {
                var snapRhs = Ember.get(req, 'employee.' + shallowDiffPath);

                diff.snapshot = {
                  lhs: Ember.get(req, 'snapshot.' + shallowDiffPath),
                  rhs: snapRhs && snapRhs.toObject && typeof snapRhs.toObject === 'function' ? snapRhs.toObject() : snapRhs
                };

                diffs.addObject(diff);
              }

              return diffs;
            }, Ember.A()));

            // (#323) Business logic: Finds the keys that we need to toss in whole objects for
            var groupedChangeTypes = ['stateTax', 'federalTax'].filter(function (key) {
              return req.diff.find(function (d) {
                return d.path[0] === key;
              });
            });

            var _loop = function _loop(groupedKey) {
              var snapshotSubHash = Ember.get(req, 'snapshot.' + groupedKey);

              if (!snapshotSubHash) {
                return 'continue';
              }

              var addDiffKeys = Object.keys(snapshotSubHash).filter(function (suffixKey) {
                return !req.diff.find(function (d) {
                  return d.path[1] === suffixKey;
                });
              });

              req.diff.addObjects(addDiffKeys.map(function (suffixKey) {
                return {
                  kind: 'E',
                  path: [groupedKey, suffixKey],
                  lhs: snapshotSubHash[suffixKey],
                  rhs: snapshotSubHash[suffixKey]
                };
              }));
            };

            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = groupedChangeTypes[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var groupedKey = _step.value;

                var _ret = _loop(groupedKey);

                if (_ret === 'continue') continue;
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }

            return req;
          })
        });
      });
    }),

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },
      itemCollapsed: function itemCollapsed() {
        this.set('allExpanded', null);
      },
      completedItem: function completedItem() {
        this.get('target').send('refresh');
      },
      complete: function complete() {
        var _this = this;

        if (this.get('working')) {
          return;
        }

        var requests = this.get('model.requests');
        this.ajaxStart();

        Ember.RSVP.map(requests.toArray(), function (request) {
          request.set('consumed', new Date());
          request.set('diff', request.get('diff').map(function (d) {
            return Object.assign(d, { snapshot: null });
          }));
          return request.save();
        }).then(function () {
          _this.ajaxSuccess();
          _this.send('completedItem');
        }).catch(this.ajaxError.bind(this));
      }
    }
  });
});