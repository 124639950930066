define('slate-payroll-admin/controllers/administration/company/paycodes', ['exports', 'slate-payroll-admin/mixins/resource-pagination', 'slate-payroll-admin/utils/title-case'], function (exports, _resourcePagination, _titleCase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_resourcePagination.default, {
    queryParams: ['page', 'sortAsc', 'sortBy'],
    page: 1,
    limit: 20,
    sortAsc: true,
    sortBy: 'created',

    _sortOptions: ['created', 'description'],

    sortOptions: Ember.computed('_sortOptions', 'sortAsc', function () {
      var sortOptions = this.get('_sortOptions');
      return sortOptions.map(function (option) {
        return typeof option === 'string' ? {
          label: (0, _titleCase.default)([option]),
          value: option
        } : option;
      });
    }),

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      }
    }
  });
});