define('slate-payroll-admin/controllers/administration/admin-users/add', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    allowSubmit: Ember.computed.and('model.email', 'model.permissions.length', 'userDoesNotExist'),
    sortPermissions: ['prettyName'],
    sortedPermissions: Ember.computed.sort('permissions', 'sortPermissions'),
    userDoesNotExist: Ember.computed.not('userExists'),

    init: function init() {
      this._super.apply(this, arguments);
      this._getPermissions();
    },


    _getPermissions: function _getPermissions() {
      var _this = this;

      this.store.findAll('permission').then(function (permissions) {
        _this.set('permissions', permissions);
      });
    },

    _end: function _end(err) {
      var errMsg = err && err.responseText ? err.responseText : err;

      if (err) {
        Ember.Logger.error(err);
      }

      this.setProperties({
        saveError: errMsg,
        saving: false
      });
    },


    actions: {
      selectAll: function selectAll() {
        this.get('model.permissions').addObjects(this.get('permissions'));
      },
      deselectAll: function deselectAll() {
        this.get('model.permissions').clear();
      },
      saveUser: function saveUser() {
        var _this2 = this;

        if (this.get('saving')) {
          return;
        }

        var end = this._end.bind(this);

        end();
        this.set('saving', true);

        var user = this.get('model');

        user.save().then(function () {
          end();
          _this2.transitionToRoute('administration.admin-users.index');
        }).catch(end);
      }
    }
  });
});