define('slate-payroll-admin/routes/administration/company/users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.query('user', {
        company: this.modelFor('administration.company').get('id'),
        sort: {
          username: 1
        }
      });
    }
  });
});