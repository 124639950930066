define('slate-payroll-admin/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var errorRouteMap = {
    401: 'unauthorized',
    404: 'not-found',
    500: 'error',
    400: 'error'
  }; /* eslint-disable no-undef */
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var _this = this;

      return this.store.findAll('session').then(function (sessions) {
        var existingSession;

        sessions.forEach(function (session) {
          if (moment(session.get('expires')).isAfter(moment())) {
            existingSession = session;
          } else {
            session.destroyRecord();
          }
        });

        if (existingSession) {
          _this.session.set('content', existingSession);
        }
      });
    },


    actions: {
      error: function error(_error) {
        Ember.Logger.error(_error);

        var route = 'error',
            err = _error.errors ? _error.errors[0] : _error;

        if (err && err.status) {
          var routeInMap = errorRouteMap[err.status];

          if (routeInMap) {
            route = routeInMap;
          }
        }

        Ember.Logger.debug('Routing to', route, 'to handle UX error...');

        this.controllerFor(route).set('fromError', err);
        this.transitionTo('/' + route);
      },
      logout: function logout() {
        this.session.destroySession();
      },
      loading: function loading(transition) {
        NProgress.start();
        transition.finally(function () {
          NProgress.done();
        });
      }
    }
  });
});