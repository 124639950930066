define('slate-payroll-admin/controllers/administration/company/custom-reports/edit', ['exports', 'slate-payroll-admin/mixins/controller-abstractions/add-edit'], function (exports, _addEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_addEdit.default, {
    processTypes: ['aggregate'],
    models: ['Punch', 'Employee', 'CompanyPaycode'],
    paramTypes: ['date', 'string', 'boolean', 'number'],
    transitionAfterSave: 'administration.company.custom-reports',
    transitionWithModel: false,

    actions: {
      validateJSON: function validateJSON() {
        this.setProperties({
          jsonErr: null,
          jsonValid: null
        });

        var err = void 0;

        try {
          JSON.parse(this.get('model.layerAggregationStages'));
        } catch (e) {
          err = e;
        }

        this.setProperties({
          jsonValid: !err,
          jsonErr: err
        });
      },
      addParam: function addParam() {
        var _ref = this.getProperties('pendingParamName', 'pendingParamType') || {},
            pendingParamName = _ref.pendingParamName,
            pendingParamType = _ref.pendingParamType;

        if (!pendingParamName || !pendingParamType) {
          return;
        }

        this.get('model.parameters').addObject({
          paramKey: pendingParamName,
          paramType: pendingParamType
        });

        this.setProperties({
          pendingParamName: null,
          pendingParamType: null
        });
      },
      removeParam: function removeParam(param) {
        this.get('model.parameters').removeObject(param);
      }
    }
  });
});