define('slate-payroll-admin/components/data-explorer/data-point-value-field', ['exports', 'slate-payroll-admin/config/models'], function (exports, _models) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var defaultValues = ['$defined', '$undefined'],
      booleanSelectValues = [true, false].concat(defaultValues),
      multiBelongsTo = ['is one of', 'is not one of'];

  exports.default = Ember.Component.extend({
    booleanSelectValues: booleanSelectValues,
    tagName: 'span',
    dataPointValue: null,
    isDateRange: Ember.computed.equal('dataPoint.type', 'date'),
    isBoolean: Ember.computed.reads('dataPoint.isBoolean'),
    isNumber: Ember.computed.reads('dataPoint.isNumber'),
    isString: Ember.computed.reads('dataPoint.isString'),
    isDate: Ember.computed.reads('dataPoint.isDate'),
    isRelationship: Ember.computed.reads('dataPoint.isRelationship'),
    multiRelationship: Ember.computed.equal('dataPoint.kind', 'hasMany'),
    operatorIsRegex: Ember.computed.equal('operator', '$regex'),

    init: function init() {
      this._super.apply(this, arguments);
      this.setDefaultBooleanValue();
    },


    multiModelSelect: Ember.computed('dataPoint.kind', 'operator', function () {
      return this.get('dataPoint.kind') === 'hasMany' || multiBelongsTo.indexOf(this.get('operator')) > -1;
    }),

    setDefaultBooleanValue: Ember.observer('isBoolean', 'dataPoint', function () {
      var _this = this;

      if (this.get('isBoolean')) {
        Ember.run.next(function () {
          _this.set('dataPointValue', _this.get('booleanSelectValues')[0]);
        });
      }
    }),

    resetValue: Ember.observer('dataPoint', 'operator', function () {
      this.set('dataPointValue', null);
    }),

    fixtureSuggestions: Ember.computed('dataPoint.field', function () {
      var fieldKey = this.get('dataPoint.field');

      return defaultValues.map(function (v, i) {
        var obj = {
          _id: i + 1
        };

        obj[fieldKey] = v;

        return obj;
      });
    }),

    lazyModelSettings: Ember.computed('isRelationship', 'dataPoint.relationshipModel', function () {
      var modelName = this.get('dataPoint.relationshipModel');

      if (this.get('isRelationship') && modelName) {
        var setting = _models.modelSettings[modelName];
        return setting || { label: 'id' };
      }
    })
  });
});