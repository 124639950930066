define('slate-payroll-admin/config/models', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var modelSettings = {
    employee: {
      label: 'fullName',
      selectQuery: 'name'
    },
    department: { label: 'name' },
    location: { label: 'name' },
    company: { label: 'name' },
    'company-paycode': {
      selectQuery: 'paycode',
      label: 'paycode.description'
    },
    'pay-period': { label: 'paidOn' }
  };

  var availableQueryModels = ['employee', 'department', 'location', 'pay-period', 'paystub', 'square', 'automated-paycode', 'punch'];

  var employeeModelArrayPaths = ['bankAccounts', 'balances'];

  exports.modelSettings = modelSettings;
  exports.availableQueryModels = availableQueryModels;
  exports.employeeModelArrayPaths = employeeModelArrayPaths;
});