define('slate-payroll-admin/components/data-explorer/query-result-value', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var QueryResultValueComponent = Ember.Component.extend({
    isDate: Ember.computed('value', function () {
      var value = this.get('value');
      return value ? (0, _moment.default)(value, _moment.default.ISO_8601, true).isValid() : false;
    })
  }); /* eslint-disable no-undef */


  QueryResultValueComponent.reopenClass({
    positionalParams: ['value']
  });

  exports.default = QueryResultValueComponent;
});