define('slate-payroll-admin/routes/administration/company/custom-reports/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.query('custom-report', {
        company: this.modelFor('administration.company').get('id')
      });
    }
  });
});