define('slate-payroll-admin/components/data-explorer/total-rows-for-column', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    total: Ember.computed('column', 'rows.[]', function () {
      var rows = this.get('rows'),
          column = this.get('column');

      return rows.reduce(function (sum, n) {
        var val = Ember.get(n, column.name);
        return val ? sum + val : sum;
      }, 0);
    })
  });
});