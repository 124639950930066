define('slate-payroll-admin/routes/administration/company/user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        users: this.store.findRecord('user', params.userid),
        permissions: Ember.RSVP.resolve(this.get('session.currentUser')).then(function (user) {
          return user.get('permissions');
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.users,
        canSpoofUsers: model.permissions.findBy('name', 'SPOOF_USER')
      });
    }
  });
});