define('slate-payroll-admin/components/data-explorer/result-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      deleteResult: function deleteResult() {
        this.get('result').destroyRecord();
      }
    }
  });
});