define('slate-payroll-admin/controllers/administration/company/user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    spoofing: Ember.inject.service(),

    actions: {
      deleteUser: function deleteUser() {
        var _this = this;

        var user = this.get('model');

        if (!confirm('You are about to delete user: ' + user.get('username') + '')) {
          return;
        }

        user.destroyRecord().then(function () {
          _this.transitionToRoute('administration.company.users');
        });
      },
      startSpoof: function startSpoof(user) {
        this.spoofing.spoofUser(user.get('id'));
      }
    }
  });
});