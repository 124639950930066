define('slate-payroll-admin/models/permission', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    name: attr('string'),
    created: attr('date'),

    prettyName: Ember.computed('name', function () {
      var name = (this.get('name') || '').split('_'),
          verb = name.shift();

      return verb + ' ' + Ember.String.classify(name.join(' ').toLowerCase());
    })
  });
});