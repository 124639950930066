define('slate-payroll-admin/components/display/static-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var typeMap = {
    E: 'success',
    D: 'danger',
    L: 'warning'
  };

  exports.default = Ember.Component.extend({
    tagName: 'th',
    classNameBindings: ['typeColor'],

    typeColor: Ember.computed('header.type', function () {
      return typeMap[this.get('header.type')];
    })
  });
});