define('slate-payroll-admin/components/file-upload-image-preview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    attributeBindings: ['style'],

    _setStyle: Ember.observer('file', function () {
      var file = this.get('file'),
          self = this;

      if (!file) {
        return this.set('style', undefined);
      }

      var fileReader = new FileReader();

      fileReader.onload = function (e) {
        var style = 'background-image: url(\'' + e.target.result + '\'); background-repeat: no-repeat; background-size: contain; background-position: center;';
        style += 'height: ' + self.get('height') + ';';
        self.set('style', style);
      };

      fileReader.readAsDataURL(file);
    })
  });
});