define('slate-payroll-admin/components/data-explorer/group-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var groupings = ['Work Week', 'Hour', 'Day', 'Week', 'Month', 'Year'];

  exports.default = Ember.Component.extend({
    groupings: groupings,
    groupTypes: ['Time', 'Field'],
    groupType: 'Time',
    timeGrouping: Ember.computed.equal('groupType', 'Time'),

    init: function init() {
      this._super.apply(this, arguments);
      this.setGrouping();
      this.setDefaultGrouping();
    },


    setGrouping: Ember.observer('groupField', 'groupType', 'groupByTime', 'enableGrouping', function () {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        var update;

        if (_this.get('enableGrouping')) {
          update = {
            groupType: _this.get('groupType'),
            value: _this.get('timeGrouping') ? _this.get('groupByTime') : _this.get('groupField.field')
          };
        }

        _this.set('grouping', update);
      });
    }),

    setDefaultGrouping: function setDefaultGrouping() {
      this.setProperties({
        groupByTime: groupings[0],
        groupField: this.get('availableGroupPoints')[0]
      });
    },

    updateValue: Ember.observer('grouping', 'enableGrouping', function () {
      this.set('value', this.get('enableGrouping') ? this.get('grouping') : null);
    }),

    availableGroupPoints: Ember.computed('modelName', function () {
      var modelName = this.get('modelName');

      if (!modelName) {
        return Ember.A();
      }

      var fields = Ember.A(),
          Model = this.get('store').modelFor(modelName),
          modelFields = Ember.get(Model, 'fields');

      // Result is an Ember.map from employee fields, so we have
      // to transform it to this object format
      modelFields.forEach(function (kind, field) {
        var fieldData = {
          field: field,
          name: Ember.String.capitalize(Ember.String.decamelize(field).replace(/_/g, ' '))
        };

        fields.addObject(fieldData);
      });

      return fields.sortBy('name');
    })
  });
});