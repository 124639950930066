define('slate-payroll-admin/routes/administration/paycodes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      sortNameAsc: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      searchDescription: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var query = {
        page: params.page - 1 || 0,
        limit: this.get('controller.limit') || 50,
        sort: {
          description: params.sortNameAsc ? 1 : -1
        }
      };

      if (!Ember.isEmpty(params.searchDescription)) {
        query.description = {
          $regex: params.searchDescription,
          $options: 'i'
        };
      }

      return this.store.query('paycode', query);
    }
  });
});