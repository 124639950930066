define('slate-payroll-admin/controllers/administration/paycodes/add', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    nonSaveStateIcon: Ember.computed('existingPaycodeWarning', function () {
      return this.get('existingPaycodeWarning') ? 'exclamation-triangle' : 'floppy-o';
    }),

    _paycodeCheck: function _paycodeCheck() {
      var self = this;

      this.setProperties({
        __paycodeCheckEvent: null
      });

      Ember.$.getJSON('/admin-api/paycodes', {
        description: this.get('description'),
        _count: true
      }, function (res) {
        self.setProperties({
          checkingPaycode: false,
          didCheckPaycode: true
        });

        if (res.count && res.count > 0) {
          self.set('existingPaycodeWarning', res.count);
        }
      });
    },

    _shouldQueuePaycodeCheck: Ember.observer('description', function () {
      this.setProperties({
        didCheckPaycode: false,
        existingPaycodeWarning: null
      });

      if (this.get('__paycodeCheckEvent')) {
        Ember.run.cancel(this.get('__paycodeCheckEvent'));
      }

      if (!this.get('description')) {
        return this.set('checkingPaycode', false);
      }

      var scheduled = Ember.run.later(this, this._paycodeCheck, 800);
      this.setProperties({
        checkingPaycode: true,
        __paycodeCheckEvent: scheduled
      });
    }),

    _end: function _end(err, success) {
      var errMsg = err && err.responseText ? err.responseText : err;

      if (err) {
        Ember.Logger.error(err);
      }

      this.setProperties({
        saveError: errMsg,
        savingPaycode: false,
        saveSuccess: success
      });
    },


    actions: {
      submit: function submit() {
        var self = this;

        this._end();
        this.set('savingPaycode', true);

        var paycode = this.store.createRecord('paycode', {
          description: this.get('description')
        });

        paycode.save().then(function () {
          self._end(null, 'Successfully saved paycode.');
          self.set('description', null);
        }).catch(this._end.bind(this));
      }
    }
  });
});