define('slate-payroll-admin/routes/administration/company/pay-periods/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      sortAsc: {
        refreshModel: true
      },
      showFilters: {
        refreshModel: true
      },
      filterByReport: {
        refreshModel: true
      },
      filterByClosed: {
        refreshModel: true
      },
      filterByAccepting: {
        refreshModel: true
      },
      report: {
        refreshModel: true
      },
      closed: {
        refreshModel: true
      },
      accepting: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var filters = [{
        name: 'report',
        merge: {
          payroll: {
            $type: params.report ? 7 : 10 // ObjectId vs. null
          }
        }
      }, {
        name: 'closed',
        merge: {
          closedOn: {
            $type: params.closed ? 9 : 10 // Date vs. null
          }
        }
      }, {
        name: 'accepting',
        merge: {
          acceptPunches: !!params.accepting
        }
      }];

      var page = params.page - 1;
      var query = {
        page: page || 0,
        limit: this.get('controller.limit') || 5,
        sort: {
          closedOn: params.sortAsc ? 1 : -1, paidOn: -1
        },
        company: this.modelFor('administration.company').get('id')
      };

      if (params.showFilters) {
        filters.forEach(function (filter) {
          if (params['filterBy' + Ember.String.capitalize(filter.name)]) {
            Ember.$.extend(query, filter.merge);
          }
        });
      }

      return this.store.query('pay-period', query);
    }
  });
});