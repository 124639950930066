define('slate-payroll-admin/routes/administration/help-articles/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      sortBy: {
        refreshModel: true
      },
      sortAsc: {
        refreshModel: true
      },
      filterCategory: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var filters = [{
        name: 'filterCategory',
        merge: {
          category: {
            $in: params.filterDepartment
          }
        }
      }];
      var page = params.page - 1;

      var query = {
        page: page || 0,
        limit: this.get('controller.limit') || 20,
        sort: {}
      };

      query.sort[params.sortBy] = params.sortAsc ? -1 : 1;

      filters.forEach(function (filter) {
        if (params[filter.name]) {
          Ember.$.extend(query, filter.merge);
        }
      });

      return this.store.query('help-article', query);
    }
  });
});