define('slate-payroll-admin/models/pay-period', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    notes: attr('string'),
    start: attr('date'),
    end: attr('date'),
    paidOn: attr('date'),
    closedOn: attr('date'),
    acceptPunches: attr('boolean'),

    company: _emberData.default.belongsTo('company', { async: true }),
    payroll: attr('string'),

    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),

    days: Ember.computed('start', 'end', function () {
      var range = this.getProperties('start', 'end');
      return (0, _moment.default)(range.end).diff((0, _moment.default)(range.start), 'days');
    }),

    title: Ember.computed('start', 'end', function () {
      return (0, _moment.default)(this.get('start')).format('M/D/YY') + ' - ' + (0, _moment.default)(this.get('end')).format('M/D/YY');
    })
  });
});