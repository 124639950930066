define('slate-payroll-admin/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.transition(this.hasClass('nested-step-outlet'), this.use('toUp'), this.reverse('toDown'));
  };
});