define('slate-payroll-admin/app', ['exports', 'ember-resolver', 'ember-load-initializers', 'slate-payroll-admin/config/environment'], function (exports, _emberResolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var App = void 0;

  App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _emberResolver.default,
    customEvents: { paste: 'paste' }
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});