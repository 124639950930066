define('slate-payroll-admin/components/data-explorer/lazy-model-select', ['exports', 'slate-payroll-admin/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LazyModelSelectComponent = Ember.Component.extend(_uxAjaxStatusHooks.default, {
    classNames: ['lazy-model-multi-select-component'],
    classNameBindings: ['multi:lazy-model-multi-select'],
    _value: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.setValue();
      this.instanceSelectionChanged();
    },
    didInsertElement: function didInsertElement() {
      this.resolveModel();
    },


    setValue: Ember.observer('_value', function () {
      var optionValuePath = this.get('optionValuePath'),
          v = this.get('_value');
      this.get('action')(optionValuePath && v ? Ember.get(v, optionValuePath) : v);
    }),

    resolveModel: Ember.observer('modelName', function () {
      var _this = this;

      var select = 'id',
          selectors = ['optionLabelPath', 'optionValuePath', 'selectQuery'];

      this.ajaxStart();

      selectors.forEach(function (selector) {
        if (_this.get(selector)) {
          select += ' ' + _this.get(selector);
        }
      });

      return this.store.query(this.get('modelName'), { select: select }).then(function (results) {
        _this.set('resolvedModel', results);
        _this.ajaxSuccess();
      }).catch(this.ajaxError.bind(this));
    }),

    instanceSelectionChanged: Ember.observer('resolvedModel', 'value', function () {
      if (!this.get('resolvedModel')) {
        return;
      }

      var selection = this.get('value');

      var model = this.get('resolvedModel'),
          valuePath = this.get('optionValuePath');

      var update = model.find(function (item) {
        var _value = valuePath ? Ember.get(item, valuePath) : item;
        return selection === _value;
      });

      this.set('value', update);
    })
  });

  LazyModelSelectComponent.reopenClass({
    positionalParams: ['modelName']
  });

  /*
    {{lazy-model-multi-select 'my-model'
      store=store
      selectId='my-select-id'
      optionLabelPath='model.label.path'
      value=value}}
  */

  exports.default = LazyModelSelectComponent;
});