define('slate-payroll-admin/transforms/array', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Transform.extend({
        deserialize: function deserialize(serialized) {
            return Ember.typeOf(serialized) === "array" ? serialized : [];
        },

        serialize: function serialize(deserialized) {
            var type = Ember.typeOf(deserialized);
            if (type === 'array') {
                return deserialized;
            } else if (type === 'string') {
                return deserialized.split(',').map(function (item) {
                    return Ember.$.trim(item);
                });
            } else {
                return [];
            }
        }
    });
});