define('slate-payroll-admin/controllers/administration/company/paystubs', ['exports', 'slate-payroll-admin/mixins/resource-pagination', 'slate-payroll-admin/mixins/ux-ajax-status-hooks'], function (exports, _resourcePagination, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_resourcePagination.default, _uxAjaxStatusHooks.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.filterEmployees = [];
      this.limitingOptions = [20, 50, 100, 200, 500];
      this.selectionOptions = [{
        name: 'Exempt',
        filter: {
          key: 'exempt',
          value: true
        }
      }, {
        name: 'Hourly',
        filter: {
          key: 'exempt',
          value: false
        }

      }, {
        name: 'Part Time',
        filter: {
          key: 'partTime',
          value: true
        }
      }, {
        name: 'Full Time',
        filter: {
          key: 'partTime',
          value: false
        }
      }, {
        name: 'Department',
        modelName: 'department'
      }, {
        name: 'Supervisor',
        modelName: 'employee',
        key: 'supervisor'
      }];
      this.sortOptions = [{
        label: 'Created',
        value: 'created'
      }, {
        label: 'Employee',
        value: 'employeeLastName'
      }];
    },


    queryParams: ['page', 'filterEmployees', 'sortBy', 'sortAsc', 'limiting', 'showFilters', { filterPayPeriods: { type: 'array' } }],
    filterByEmployees: Ember.A(),
    filterPayPeriods: Ember.A(),
    sortBy: 'employeeLastName',
    sortAsc: true,
    page: 1,
    limit: 20,
    showFilters: false,

    punchSelection: Ember.A(),
    dateSelected: Ember.computed.or('timeIn', 'timeOut'),

    allSelected: Ember.computed('paystubSelection.[]', function () {
      return this.get('model.length') === this.get('paystubSelection.length');
    }),

    selectByValid: Ember.computed('selectBy', 'selectByRecord', function () {
      var selectBy = this.get('selectBy');
      return selectBy && (selectBy.filter || this.get('selectByRecord'));
    }),

    paystubIndexModifier: Ember.computed('page', 'limit', function () {
      return (this.page - 1) * this.limit;
    }),

    // lazyModelSettings: computed('selectBy.modelName', function () {
    //   const modelName = this.get('selectBy.modelName');

    //   if (modelName) {
    //     let setting = modelSettings[modelName];
    //     return setting || { label: 'id' };
    //   } else {
    //     return null;
    //   }
    // }),

    selectByChanged: Ember.observer('selectBy', function () {
      this.set('selectByRecord', undefined);
    }),

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },
      selectEmployee: function selectEmployee(employee) {
        this.set('employee', employee ? employee.get('id') : null);
      },
      clearDateSelection: function clearDateSelection() {
        this.setProperties({
          startSelection: null,
          endSelection: null
        });
      },
      refreshModel: function refreshModel() {
        this.send('refresh');
      },
      allSelectedChanged: function allSelectedChanged() {
        var checked = Ember.$('#select-all-checkbox').prop('checked');

        if (checked) {
          this.get('paystubSelection').addObjects(this.get('model'));
        } else {
          this.set('paystubSelection', Ember.A());
        }
      },
      setLimit: function setLimit(opt) {
        this.set('limit', opt);
      },
      selectAllEmployees: function selectAllEmployees() {
        this.get('filterByEmployees').addObjects(this.get('employees').mapBy('id'));
      },
      deselectAllEmployees: function deselectAllEmployees() {
        this.get('filterByEmployees').removeObjects(this.get('filterByEmployees'));
      },
      selectAllPayPeriods: function selectAllPayPeriods() {
        this.get('filterByPayPeriods').addObjects(this.get('payPeriods.all').mapBy('id'));
      },
      deselectAllPayPeriods: function deselectAllPayPeriods() {
        this.get('filterByPayPeriods').removeObjects(this.get('filterByPayPeriods'));
      },
      selectCustomEmployees: function selectCustomEmployees() {
        var selectBy = this.get('selectBy'),
            selectByRecord = this.get('selectByRecord'),
            selected = this.get('filterByEmployees'),
            employees = this.get('employees');

        if (!selectBy) {
          return;
        }

        if (selectBy.filter) {
          selected.addObjects(employees.filterBy(selectBy.filter.key, selectBy.filter.value).mapBy('id'));
        } else if (selectBy.modelName && selectByRecord) {
          var id = selectByRecord.get('id');
          selected.addObjects(employees.filter(function (employee) {
            var rel = employee.belongsTo(selectBy.key || selectBy.modelName),
                relValue = rel && rel.value ? rel.value() : rel;
            return relValue && relValue.get('id') === id;
          }).mapBy('id'));
        }

        this.setProperties({
          selectBy: undefined,
          selectByRecord: undefined
        });
      },
      filter: function filter() {
        var filterEmployees = this.get('filterByEmployees'),
            filterPayPeriods = this.get('filterByPayPeriods');

        this.setProperties({
          filterEmployees: filterEmployees,
          filterPayPeriods: filterPayPeriods,
          page: 1
        });
      },
      clearFilters: function clearFilters() {
        this.setProperties({
          filterByEmployees: Ember.A(),
          filterEmployees: [],
          filterByPayPeriods: Ember.A(),
          filterPayPeriods: [],
          page: 1
        });
      },
      sort: function sort(sortable) {
        if (sortable === this.get('sortBy')) {
          this.toggleProperty('sortAsc');
        } else {
          this.setProperties({
            sortBy: sortable,
            sortAsc: true
          });
        }
      },
      deletePaystub: function deletePaystub(paystub) {
        var _this = this;

        if (!confirm('Are you sure you want to delete this paystub?')) {
          return;
        }

        this.ajaxStart();

        paystub.destroyRecord().then(function () {
          _this.ajaxSuccess();
          _this.send('refresh');
        }).catch(this.ajaxError.bind(this));
      },
      printPaystub: function printPaystub(paystubId) {
        this.set('printOnInit', true);
        this.transitionToRoute('administration.company.paystub', paystubId);
      },
      downloadPaystub: function downloadPaystub(paystubId) {
        this.set('downloadOnInit', true);
        this.transitionToRoute('administration.company.paystub', paystubId);
      }
    }
  });
});