define('slate-payroll-admin/components/review-column', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    classNames: ['reviewCol'],

    isDollars: Ember.computed(function () {
      var column = this.get('columnIndex'),
          headers = this.get('headers');
      return headers[column].unit === 'Dollars' ? true : false;
    })
  });
});