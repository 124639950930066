define('slate-payroll-admin/controllers/setup-account/ds', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    allowSubmit: Ember.computed('password', 'passwordConfirm', 'activating', function () {
      var password = this.get('password');
      return this.get('activating') ? false : password && password === this.get('passwordConfirm');
    }),

    _end: function _end(err) {
      var errMsg = err && err.responseText ? err.responseText : err;

      if (err) {
        Ember.Logger.error(err);
      }

      this.setProperties({
        activationError: errMsg,
        activating: false
      });
    },


    actions: {
      activateAccount: function activateAccount() {
        var _this = this;

        var activationSignature = this.get('model.activationSignature'),
            id = this.get('model.id');

        var end = this._end.bind(this);

        end();
        this.set('activating', true);

        Ember.$.post('/admin-api/admin-user/activate/' + id, {
          activationSignature: activationSignature,
          password: this.get('password')
        }, function () {
          _this.setProperties({
            password: null,
            passwordConfirm: null,
            success: true
          });

          end();

          Ember.run.later(function () {
            _this.transitionToRoute('login');
          }, 1000);
        }).fail(end);
      }
    }
  });
});