define('slate-payroll-admin/components/data-explorer/date-range-select', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /* eslint-disable no-undef */
  var rangeTypes = ['Today', 'Last Hour', 'Last 30 Days', 'This Month', 'Last Month', 'This Year', 'Last Year', 'Custom'];

  exports.default = Ember.Component.extend({
    rangeTypes: rangeTypes,
    rangeStart: null,
    rangeEnd: null,
    classNames: ['date-range-select'],
    classNameBindings: ['isCustomRange:in-custom-ui'],
    timeInterval: 1,
    isCustomRange: Ember.computed.equal('rangeType', 'Custom'),

    init: function init() {
      this._super.apply(this, arguments);
      this.setDefaultRangeType();
      this.updateComponentValue();
    },
    setDefaultRangeType: function setDefaultRangeType() {
      this.set('rangeType', rangeTypes[0]);
    },


    updateComponentValue: Ember.observer('rangeStart', 'rangeEnd', 'rangeType', function () {
      var _this = this;

      Ember.run.next(function () {
        var update;

        if (_this.get('isCustomRange')) {
          var start = _this.get('rangeStart'),
              end = _this.get('rangeEnd');

          update = start || end ? { start: start, end: end } : undefined;
        } else {
          update = _this.get('rangeType');
        }

        _this.set('value', update);
      });
    }),

    actions: {
      editRange: function editRange(segment) {
        this.set('selectingValue', segment);
      },
      closeOverlay: function closeOverlay() {
        this.set('selectingValue', null);
      },
      setValue: function setValue(segment) {
        var _this2 = this;

        var date = this.get('dateSelection'),
            timeSelection = this.get('timeSelection'),
            time = timeSelection ? (0, _moment.default)(timeSelection, 'h:mm A') : timeSelection;

        var segmentValue = (0, _moment.default)(date);

        if (time) {
          segmentValue.set('hour', time.get('hour')).set('minute', time.get('minute')).toDate();
        } else if (segment === 'start') {
          segmentValue = segmentValue.startOf('day').toDate();
        } else {
          segmentValue = segmentValue.endOf('day').toDate();
        }

        this.set('range' + Ember.String.capitalize(segment), segmentValue);
        this.setProperties({
          clearTime: true,
          clearDate: true
        });

        Ember.run.next(function () {
          _this2.send('closeOverlay');
        });
      }
    }
  });
});