define('slate-payroll-admin/routes/administration/company/groups/edit-department', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('department', params.departmentid);
    },


    actions: {
      willTransition: function willTransition(transition) {
        var _this = this;

        var controller = this.controller;

        if (!controller.get('showModal')) {
          return;
        }

        if (controller.get('model.hasDirtyAttributes')) {
          controller.get('model').rollbackAttributes();
        }

        transition.abort();
        controller.set('showModal', false);

        Ember.$('#' + controller.get('modalId')).one('hidden.bs.modal', function () {
          Ember.run.bind(_this, transition.retry());
        });
      },
      didTransition: function didTransition() {
        // We must turn the modal on every time we enter the route
        this.controller.set('showModal', true);
      },
      refresh: function refresh() {
        return true;
      }
    }
  });
});