define('slate-payroll-admin/models/punch', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    timeIn: attr('date'),
    timeOut: attr('date'),
    hours: attr('number'),
    days: attr('number'),
    addEarnings: attr('number'),
    deduction: attr('number'),
    lunch: attr('number'), // Hours
    breaks: attr('number', { defaultValue: 0 }), // Hours
    onBreak: attr('date'),

    status: attr('string'),
    notes: attr('string'),
    ip: attr('string'),

    timeInGeoLat: attr('number'),
    timeInGeoLng: attr('number'),
    timeOutGeoLat: attr('number'),
    timeOutGeoLng: attr('number'),

    // Relational
    employee: _emberData.default.belongsTo('employee', { async: true }),
    company: _emberData.default.belongsTo('company', { async: true }),
    companyPaycode: _emberData.default.belongsTo('company-paycode', { inverse: false, async: true }),
    payPeriod: _emberData.default.belongsTo('pay-period', { async: true }),

    // Legacy Fields
    legacyEntryId: attr('number'),
    legacyPayPeriodId: attr('number'),
    legacyCostCenterId: attr('number'),

    updatedOn: attr('date'),
    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),

    incomplete: Ember.computed('timeIn', 'timeOut', function () {
      return this.get('timeIn') && !this.get('timeOut');
    })
  });
});