define('slate-payroll-admin/serializers/query', ['exports', 'slate-payroll-admin/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      dataPoints: { embedded: 'always' },
      displayPoints: { embedded: 'always' },
      queryGroups: { embedded: 'always' }
    }
  });
});