define('slate-payroll-admin/routes/administration/data-explorer/index/reports/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll('query');
    },


    actions: {
      willTransition: function willTransition() {
        return true;
      }
    }
  });
});