define('slate-payroll-admin/router', ['exports', 'slate-payroll-admin/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });

  Router.map(function () {
    this.route('administration', function () {
      this.route('settings');
      this.route('sync');
      this.route('changes', function () {
        this.route('company', { path: '/company/:company_id' });
        this.route('change', { path: '/change/:change_id' }, function () {
          this.route('details');
        });
      });
      this.route('changes-legacy', { path: '/l-changes' });
      this.route('change-legacy', { path: '/l-change/:id' }, function () {
        this.route('index', { path: '/' });
        this.route('diff');
      });

      this.route('companies', function () {
        this.route('add', function () {
          this.route('locations');
          this.route('paycodes');
          this.route('settings');
          this.route('employees');
          this.route('users');
          this.route('complete');
          this.route('overtime-rules');
        });
      });
      this.route('company', { path: '/company/:id' }, function () {
        this.route('employees');
        this.route('paycodes', function () {
          this.route('edit', { path: '/:paycodeid/edit' });
          this.route('add');
        });
        this.route('pay-periods', function () {
          this.route('index', { path: '/' });
          this.route('html-report', { path: '/:payrollid/report' });
          this.route('review-auto');
          this.route('add');
        });
        this.route('paystubs');
        this.route('paystub', { path: 'paystub/:paystub_id' });
        this.route('settings');
        this.route('payrolls');
        this.route('payroll', { path: '/payroll/:payroll_id' });
        this.route('users');
        this.route('user', { path: '/user/:userid' }, function () {
          this.route('edit');
        });
        this.route('groups', function () {
          this.route('add-location');
          this.route('add-department');
          this.route('edit-location', { path: '/:locationid/edit-location' });
          this.route('edit-department', { path: '/:departmentid/edit-department' });
        });
        this.route('custom-reports', function () {
          this.route('index', { path: '/' });
          this.route('new');
          this.route('edit', { path: '/:id' });
        });
        this.route('balances');
        this.route('tax-forms');
      });

      this.route('paycodes', function () {
        this.route('add');
      });

      this.route('paycode', { path: '/paycode/:id' }, function () {
        this.route('company-paycodes');
      });

      this.route('employee', { path: '/employee/:employee_id' }, function () {
        this.route('edit');
      });

      this.route('admin-users', function () {
        this.route('add');
      });
      this.route('help-articles', function () {
        this.route('add');
        this.route('edit', { path: '/:id/edit' });
        this.route('view', { path: '/:id/view' });
      });

      this.route('data-explorer', { path: '/data' }, function () {
        this.route('index', { path: '/explore' }, function () {
          this.route('query');
          this.route('reports', function () {
            this.route('view', { path: '/:id' }, function () {
              this.route('results');
              this.route('result', { path: '/result/:resultid' });
            });
            this.route('run', { path: '/:id/run' });
          });
        });
      });
      this.route('messaging', function () {
        this.route('compose');
      });
      this.route('time-off-balances');
    });

    this.route('setup-account', { path: '/setup-account' }, function () {
      this.route('error');
      this.route('ds', { path: '/:userid' });
    });

    this.route('login');
    this.route('2fa', function () {
      this.route('verify');
      this.route('remind');
      this.route('setup');
    });

    this.route('test');
    this.route('error');
    this.route('not-found');
    this.route('unauthorized');
    this.route('catchall', { path: '/*wildcard' });

    this.route('company', function () {
      this.route('paystubs');

      this.route('pay-periods', function () {
        this.route('paystubs');
      });
    });
  });

  exports.default = Router;
});