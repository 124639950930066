define('slate-payroll-admin/routes/administration/companies/add/paycodes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      description: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var query = {
        limit: 50,
        page: params.page - 1 || 0,
        sort: { description: 1 }
      };

      if (params.description && params.description.length > 0) {
        query.description = {
          $regex: params.description,
          $options: 'i'
        };
      }

      return this.store.query('paycode', query);
    }
  });
});