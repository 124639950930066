define('slate-payroll-admin/controllers/administration/company', ['exports', 'slate-payroll-admin/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    pollTaskStatus: function pollTaskStatus() {
      var id = this.get('task._id');

      if (!id) {
        return;
      }

      Ember.$.getJSON('/admin-api/utilities/import/' + id).done(Ember.run.bind(this, function (res) {
        var task = res.taskStatus;

        this.set('task', task);

        if (task.running) {
          Ember.run.later(this, this.pollTaskStatus, 200);
        }
      })).fail(this.ajaxError.bind(this));
    },


    actions: {
      deleteCompany: function deleteCompany() {
        var _this = this;

        var company = this.get('model');

        if (!confirm('You are about to delete ' + company.get('name') + '.')) {
          return;
        }

        company.destroyRecord().then(function () {
          _this.transitionToRoute('administration.companies');
        });
      },
      initiateImport: function initiateImport() {
        var company = this.get('model');

        this.ajaxStart();

        if (!confirm('Are you sure you want to start an import?')) {
          this.ajaxSuccess();
          return;
        }

        Ember.$.post('/admin-api/utilities/import', { company: company.get('id') }).done(Ember.run.bind(this, function (res) {
          this.set('task', res.taskStatus);
          this.pollTaskStatus();
          this.ajaxSuccess();
        })).fail(this.ajaxError.bind(this));
      },
      sendWelcomeEmails: function sendWelcomeEmails() {
        var company = this.get('model');

        this.ajaxStart();

        if (company.get('sentWelcomeEmails') && !confirm('Welcome emails have already been sent for this company. Are you sure you want to send them again?')) {
          this.ajaxSuccess();
          return;
        }

        Ember.$.post('/admin-api/companies/' + company.id + '/welcome-emails', { force: true }).done(Ember.run.bind(this, function () {
          this.ajaxSuccess();
          company.set('sentWelcomeEmails', true);
        })).fail(this.ajaxError.bind(this));
      }
    }
  });
});