define('slate-payroll-admin/controllers/administration/employee/edit', ['exports', 'slate-payroll-admin/config/app-data'], function (exports, _appData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    suffixes: _appData.suffixes,
    genders: _appData.genders,
    states: _appData.states,
    filingStatuses: _appData.filingStatuses,
    maritalStatuses: _appData.maritalStatuses,
    bankAccountTypes: _appData.bankAccountTypes,
    payTypes: ['Hourly', 'Salary'],
    achAmountTypes: ['$', '%'],

    modalId: 'edit-employee-effective-modal',

    payIsSalary: Ember.computed.equal('model.payType', 'Salary'),

    _setPayRate: Ember.observer('payRate', 'model.payType', function () {
      if (!this.get('__didSetPayRate')) {
        return;
      }

      this.get('model').setProperties({
        payRateHourly: undefined,
        payRateSalary: undefined
      });

      this.set('model.payRate' + this.get('model.payType'), this.get('payRate'));
    }),

    _initDateValue: Ember.observer('model.hireDate', function () {
      if (this.get('__didSetDateValue')) {
        return;
      }

      this.set('__didSetDateValue', true);

      this.set('hireDateValue', moment(this.get('model.hireDate')).format('DD MMMM, YYYY'));
    }),

    _initPayType: Ember.observer('model.isLoaded', function () {
      if (this.get('__didSetPayRate') || !this.get('model.isLoaded')) {
        return;
      }

      this.set('__didSetPayRate', true);

      var data = this.get('model').getProperties('payRateSalary', 'payRateHourly');

      if (data.payRateHourly) {
        this.setProperties({
          payRate: data.payRateHourly,
          'model.payType': 'Hourly'
        });
      } else if (data.payRateSalary) {
        this.setProperties({
          payRate: data.payRateSalary,
          'model.payType': 'Salary'
        });
      }
    }),

    _setLocations: Ember.observer('model.company.locations', function () {
      var self = this,
          companyId = this.get('model.company.id');

      this.store.query('location', { company: companyId }).then(function (data) {
        self.set('locations', data);
      });
    }),

    _setDepartments: Ember.observer('model.company.departments', function () {
      var self = this,
          companyId = this.get('model.company.id');

      this.store.query('department', { company: companyId }).then(function (data) {
        self.set('departments', data);
      });
    }),

    _end: function _end(err) {
      var errMsg = err && err.responseText ? err.responseText : err;

      if (errMsg && errMsg.errors) {
        errMsg = errMsg.errors.mapBy('detail').join(', ');
      }

      if (err) {
        Ember.Logger.error(err);
      }

      this.setProperties({
        saveError: errMsg,
        saving: false
      });
    },


    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },
      addPendingBankAccount: function addPendingBankAccount() {
        this.set('pendingBankAccount', this.store.createRecord('bank-account'));
        this.set('pendingBankTypeSelection', undefined);
      },
      deletePendingBankAccount: function deletePendingBankAccount() {
        this.get('pendingBankAccount').destroyRecord();
        this.set('pendingBankAccount', undefined);
        this.set('pendingBankTypeSelection', undefined);
      },
      removeBankAccount: function removeBankAccount(bankAccount) {
        this.get('model.bankAccounts').removeObject(bankAccount);
      },
      addBankAccount: function addBankAccount(bankAccount) {
        var model = this.get('model');

        bankAccount.set('employee', model);
        model.get('bankAccounts').addObject(bankAccount);
        this.set('pendingBankAccount', undefined);
      },
      saveChanges: function saveChanges() {
        if (this.get('saving')) {
          return;
        }

        this._end();
        this.set('saving', true);

        var employee = this.get('model'),
            effective = this.get('effectiveDate'),
            self = this;

        employee.set('effective', effective);

        employee.save().then(function (savedRecord) {
          savedRecord.get('bankAccounts').filterBy('id', null).invoke('deleteRecord');
          employee.get('overtimeRules').filterBy('id', null).invoke('deleteRecord');
          self._end();
          self.transitionToRoute('administration.employee.index');
        }).catch(this._end.bind(this));
      }
    }
  });
});