define('slate-payroll-admin/components/x-existence-check', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['title'],
    classNames: ['input-group-addon'],
    classNameBindings: ['groupClass'],

    _queryPoint: Ember.computed.reads('queryPoint'),
    _key: Ember.computed.reads('key'),
    _modelKey: Ember.computed.reads('modelKey'),
    _proposed: Ember.computed.reads('proposed'),
    _existenceSpecific: Ember.computed.reads('existenceSpecificEndpoint'),

    init: function init() {
      this._super.apply(this, arguments);
      (true && !(this.get('_queryPoint')) && Ember.assert('You must add a queryPoint attr to the x-existence-check component.', this.get('_queryPoint')));
    },


    groupClass: Ember.computed('exists', function () {
      var exists = this.get('exists');
      return typeof exists === 'boolean' ? exists ? 'input-group-addon-danger' : 'input-group-addon-success' : false;
    }),

    title: Ember.computed('exists', 'didCheckExistence', 'checking', function () {
      if (this.get('checking')) {
        return 'Loading...';
      } else if (this.get('didCheckExistence')) {
        return this.get('exists') ? 'Already exists.' : 'Good to go!';
      }
    }),

    _end: function _end(err) {
      var errMsg = err && err.responseText ? err.responseText : err;

      if (errMsg && errMsg.errors) {
        errMsg = errMsg.errors.mapBy('detail').join(', ');
      }

      if (err) {
        Ember.Logger.error(err);
      }

      this.setProperties({
        loadError: errMsg,
        checking: false
      });
    },
    _existenceCheck: function _existenceCheck() {
      var self = this,
          existenceSpecific = this.get('_existenceSpecific'),
          proposed = this.get('_proposed'),
          key = this.get('_key'),
          queryPoint = this.get('_queryPoint');

      this.set('_checkEvent', null);

      var _end = this._end.bind(this),
          url = queryPoint,
          query = {};

      _end();
      this.set('checking', true);

      if (existenceSpecific) {
        url += '/' + proposed;
      } else {
        (true && !(!!key) && Ember.assert('x-existence-check must have a key for non-existence-specific query points.', !!key));

        query[key] = proposed;
      }

      Ember.$.getJSON(url, query, function (res) {
        _end();

        self.setProperties({
          didCheckExistence: true,
          exists: existenceSpecific ? res.exists : !!res[self.get('_modelKey')].length
        });

        self.send('change', self.get('exists'));
      }).fail(_end);
    },


    _shouldQueueCheck: Ember.observer('_proposed', function () {
      this.set('didCheckExistence', false);

      if (this.get('_checkEvent')) {
        Ember.run.cancel(this.get('_checkEvent'));
      }

      if (!this.get('_proposed')) {
        return this.set('checking', false);
      }

      this.set('exists', null);
      this.send('change', null);

      var scheduled = Ember.run.later(this, this._existenceCheck, 800);
      this.setProperties({
        checking: true,
        _checkEvent: scheduled
      });
    }),

    actions: {
      change: function change(exists) {
        this.get('action')(exists);
      }
    }
  });
});