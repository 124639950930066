define('slate-payroll-admin/controllers/administration/paycodes/index', ['exports', 'slate-payroll-admin/mixins/resource-pagination'], function (exports, _resourcePagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_resourcePagination.default, {
    queryParams: ['page', 'sortNameAsc', 'searchDescription'],
    page: 1,
    limit: 50,
    sortNameAsc: true,
    searchDescription: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.searchTextState();
    },


    searchTextState: Ember.observer('isSearching', function () {
      if (!this.get('_hasRanSearchTextInit')) {
        return this.setProperties({
          _hasRanSearchTextInit: true,
          searchText: this.get('searchDescription')
        });
      }

      var searchText = this.get('searchText');

      if (!this.get('isSearching')) {
        this.set('searchDescription', null);
      } else if (!Ember.isEmpty(searchText)) {
        this.set('searchDescription', searchText);
      }
    }),

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },
      changeSearchText: function changeSearchText() {
        this.set('searchDescription', this.get('searchText'));
      }
    }
  });
});