define('slate-payroll-admin/components/file-upload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    classNames: ['file-upload'],
    classNameBindings: ['dropzone'],

    dragEnter: function dragEnter(e) {
      e.stopPropagation();
      e.preventDefault();
    },
    dragOver: function dragOver(e) {
      e.stopPropagation();
      e.preventDefault();

      Ember.$().addClass('activated');
    },
    dragLeave: function dragLeave(e) {
      e.stopPropagation();
      e.preventDefault();

      Ember.$().removeClass('activated');
    },
    drop: function drop(e) {
      e.stopPropagation();
      e.preventDefault();

      Ember.$().removeClass('activated');
      this._validateFiles(e.dataTransfer.files);
    },


    // Settings
    dropzone: true,
    defaultMaxFiles: 1,
    maxSize: 8, // in mb
    defaultExtensions: ['pdf', 'jpg', 'jpeg'],

    files: Ember.A(),
    doesNotHaveFiles: Ember.computed.not('hasFiles'),
    showDropzoneText: Ember.computed.and('dropzone', 'doesNotHaveFiles'),

    // Private
    _validateFiles: function _validateFiles(files) {
      this._clearError();

      if (this.get('clear') || !this.isMulti) {
        this._clearFiles();
      }

      var self = this,
          multi = this.isMulti,
          max = this.maxFiles,
          _files = this.get('files');

      var extReg = new RegExp(this.extensions.map(function (ext, index, exts) {
        return exts.length - 1 === index ? ext : ext + '|';
      }).join(''), 'i');

      var handleError = function handleError(err) {
        self.set('fileError', err);
      };

      for (var i in files) {
        if (!files.hasOwnProperty(i) || _typeof(files[i]) !== 'object') {
          continue;
        }

        var file = files[i];

        if (!extReg) {
          _files.addObject(file);
          continue;
        }

        if (!extReg.test(file.name)) {
          handleError('One or more file types are not supported.');
        } else if (!multi && _files.length > 0 || _files.length > max - 1) {
          handleError('File limit reached.');
        } else if (Math.floor(file.size / 1000000) > this.get('maxSize')) {
          handleError('Max file size exceeded.');
        } else {
          _files.addObject(file);
        }
      }
    },
    _clearError: function _clearError() {
      this.set('fileError', null);
    },
    _clearFiles: function _clearFiles() {
      this.set('files', Ember.A());
    },
    _resetFileInput: function _resetFileInput() {
      var $fileInput = this.$().find('.file-upload-hidden-input');
      $fileInput.wrap('<form>').parent('form').trigger('reset');
      $fileInput.unwrap();
    },


    // Computed
    inputId: Ember.computed('elementId', function () {
      return this.get('elementId') + '-input';
    }),

    extensions: Ember.computed('allowedExtensions', 'defaultExtensions', function () {
      return this.allowedExtensions || this.defaultExtensions;
    }),

    maxFiles: Ember.computed('fileLimit', 'defaultMaxFiles', function () {
      return this.fileLimit || this.defaultMaxFiles;
    }),

    isMulti: Ember.computed('fileLimit', 'defaultMulti', 'multi', function () {
      return this.fileLimit > 1;
    }),

    dropzoneId: Ember.computed('elementId', function () {
      return this.get('elementId') + '-dropzone';
    }),

    shouldSetHasFiles: Ember.observer('files.[]', function () {
      this.set('hasFiles', this.get('files') && this.get('files.length') > 0);
    }),

    actions: {
      triggerFileInput: function triggerFileInput() {
        this.$().find('#' + this.get('inputId')).click();
      },
      selectFile: function selectFile() {
        this._validateFiles(this.$().find('#' + this.get('inputId'))[0].files);
      },
      removeFile: function removeFile(file) {
        this.get('files').removeObject(file);
        if (!this.get('files.length')) {
          this._resetFileInput();
        }
      }
    }
  });
});