define('slate-payroll-admin/controllers/administration/company/pay-periods/index', ['exports', 'slate-payroll-admin/mixins/resource-pagination'], function (exports, _resourcePagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_resourcePagination.default, {
    queryParams: ['page', 'sortAsc', 'showFilters', 'filterByReport', 'filterByClosed', 'filterByAccepting', 'report', 'closed', 'accepting'],
    sortAsc: true,
    page: 1,
    limit: 5,
    showFilters: false,
    filterByReport: false,
    filterByClosed: false,
    filterByAccepting: false,
    report: false,
    closed: false,
    accepting: false,

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      }
    }
  });
});