define('slate-payroll-admin/components/diff-item', ['exports', 'slate-payroll-admin/config/models'], function (exports, _models) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    tagName: 'tr',

    humanizedPath: Ember.computed('diff.path', 'isArrayDiff', function () {
      var path = [].concat(_toConsumableArray(this.get('diff.path') || []));
      var humanize = function humanize(a) {
        return a.map(function (seg) {
          return seg === '_id' ? 'ID' : seg;
        }).join(' ').replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
      };

      return humanize(this.get('isArrayDiff') ? [path.shift()] : path);
    }),

    isArrayDiff: Ember.computed('diff.{kind,path}', function () {
      return this.get('diff.kind') === 'A' || _models.employeeModelArrayPaths.indexOf((this.get('diff.path') || [])[0]) > -1;
    }),

    destructuredSnapshot: Ember.computed('isArrayDiff', 'diff.snapshot{lhs.[],rhs.[]}', function () {
      if (!this.get('isArrayDiff') || !this.get('diff.snapshot')) {
        return null;
      }

      var _get = this.get('diff.snapshot'),
          lhs = _get.lhs,
          rhs = _get.rhs;

      if (lhs && lhs.toJSON) {
        lhs = lhs.toJSON();
      }

      if (rhs && rhs.toArray) {
        rhs = rhs.toArray();
      }

      var destructure = function destructure(_obj) {
        var obj = _obj.toJSON ? _obj.toJSON() : _obj;
        return Object.keys(obj).filter(function (k) {
          return k !== 'id' && k !== '_id';
        }).map(function (key) {
          return { key: key, value: Ember.get(obj, key), path: [key] };
        });
      };

      return {
        lhs: lhs && lhs.map(function (i) {
          return destructure(i);
        }),
        rhs: rhs && rhs.map(function (i) {
          return destructure(i);
        })
      };
    })
  });
});