define('slate-payroll-admin/mixins/route-abstractions/resource', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    queryParams: {
      page: { refreshModel: true },
      limit: { refreshModel: true },
      sortBy: { refreshModel: true },
      sortAsc: { refreshModel: true }
    },

    model: function model(params) {
      var query = {
        page: params.page - 1 || 0,
        limit: params.limit
      };

      if (this.get('sort')) {
        query.sort = this.get('sort');
      }

      if (params.sortBy) {
        if (!query.sort) {
          query.sort = {};
        }

        query.sort[params.sortBy] = params.sortAsc ? 1 : -1;
      }

      if (this.get('query')) {
        Ember.$.extend(query, this.get('query'));
      }

      var mutate = this.mutateQuery,
          sorter = this.sortQuery;

      if (mutate && typeof mutate === 'function') {
        mutate.call(this, query, params);
      }

      if (sorter && typeof sorter === 'function') {
        sorter.call(this, query);
      }

      return this.store.query(this.get('modelName'), query);
    }
  });
});