define('slate-payroll-admin/serializers/company', ['exports', 'slate-payroll-admin/serializers/application', 'ember-data', 'slate-payroll-admin/utils/serialize-object', 'slate-payroll-admin/utils/expand-serialized-object'], function (exports, _application, _emberData, _serializeObject, _expandSerializedObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,

    attrs: {
      employees: { serialize: 'ids' },
      departments: { serialize: 'ids' },
      locations: { serialize: 'ids' },
      companyPaycodes: { serialize: 'ids' },
      payPeriods: { serialize: 'ids' },
      users: { serialize: 'ids' },
      overtimeRules: { embedded: 'always' }
    },

    normalize: function normalize(modelClass, hash) {
      if (modelClass.modelName === 'company') {
        (0, _serializeObject.default)(hash, true, 'address');
        (0, _serializeObject.default)(hash, false, 'notificationSettings', 'roleSettings', 'notificationEmails');
      }
      return this._super.apply(this, arguments);
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var attr = relationship.key;

      if (this.noSerializeOptionSpecified(attr)) {
        this._super(snapshot, json, relationship);
        return;
      }
      var includeIds = this.hasSerializeIdsOption(attr);
      var includeRecords = this.hasSerializeRecordsOption(attr);
      var key, hasMany;

      if (includeIds) {
        key = this.keyForRelationship(attr, relationship.kind, 'serialize');
        if (snapshot.hasMany(attr, { ids: true })) {
          json[key] = snapshot.hasMany(attr, { ids: true }).filter(function (val) {
            return !!val;
          });
        }
      } else if (includeRecords) {
        key = this.keyForAttribute(attr, 'serialize');
        hasMany = snapshot.hasMany(attr);

        (true && Ember.warn('The embedded relationship \'' + key + '\' is undefined for \'' + snapshot.modelName + '\' with id \'' + snapshot.id + '\'. Please include it in your original payload.', Ember.typeOf(hasMany) !== 'undefined', { id: 'ds.serializer.embedded-relationship-undefined' }));


        json[key] = Ember.A(hasMany).map(function (embeddedSnapshot) {
          var embeddedJson = embeddedSnapshot.record.serialize({ includeId: true });
          this.removeEmbeddedForeignKey(snapshot, embeddedSnapshot, relationship, embeddedJson);
          return embeddedJson;
        }, this);
      }
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      json.notificationSettings = {
        incompletePunches: json.incompletePunches
      };

      json.notificationEmails = {
        incompletePunchNotificationEmails: json.incompletePunchNotificationEmails
      };

      delete json.incompletePunches;
      delete json.incompletePunchNotificationEmails;

      (0, _expandSerializedObject.default)(json, 'address');
      return json;
    }
  });
});