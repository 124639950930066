define('slate-payroll-admin/models/payroll', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    descriptor: attr('string'),
    altDescriptor: attr('string'),
    columnHeaders: attr('array'),
    rows: attr('array'),
    staticHeaders: attr('array'),
    statics: attr('array'),
    totalsByColumns: attr('array'),
    totalsByColumnsFixed: attr('array'),

    company: belongsTo('company', { async: true, inverse: null }),
    payPeriod: belongsTo('pay-period', { async: true, inverse: null }),

    finalized: attr('boolean'),
    finalizedOn: attr('date'),
    notified: attr('boolean'),
    paidOn: attr('date'),

    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });
});