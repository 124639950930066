define('slate-payroll-admin/models/help-article', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    title: attr('string'),
    body: attr('string'),
    youtubeUrl: attr('string'),
    votesDown: attr('number'),
    votesUp: attr('number'),
    tags: attr('array'),
    generated: attr('boolean'),
    category: _emberData.default.belongsTo('help-category', { async: true }),
    created: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });
});