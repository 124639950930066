define('slate-payroll-admin/controllers/administration/companies/index', ['exports', 'slate-payroll-admin/mixins/resource-pagination'], function (exports, _resourcePagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_resourcePagination.default, {
    queryParams: ['page', 'sortNameAsc'],
    page: 1,
    limit: 15,
    sortNameAsc: true,

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      }
    }
  });
});