define('slate-payroll-admin/components/list/tax-form-grid-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      delete: function _delete() {
        this.get('delete')(this.get('taxForm'));
      }
    }
  });
});