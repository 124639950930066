define('slate-payroll-admin/routes/administration/changes/index', ['exports', 'slate-payroll-admin/mixins/route-abstractions/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resource.default, {
    modelName: 'company',

    model: function model() {
      var fetchRequestCount = function fetchRequestCount(company) {
        return Ember.$.getJSON('/api/change-requests/', {
          _count: true,
          company: company.get('id'),
          consumed: { $exists: false }
        }).then(function (result) {
          return { company: company, count: result.count };
        });
      };

      var filterOutZeros = function filterOutZeros(group) {
        return group.count > 0;
      };

      return this._super.apply(this, arguments).then(function (companies) {
        return Ember.RSVP.filter(companies.map(fetchRequestCount), filterOutZeros);
      });
    }
  });
});