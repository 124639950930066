define('slate-payroll-admin/utils/plural-array-descriptor', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = pluralArrayDescriptor;
  function pluralArrayDescriptor(array, suffixText) {
    var length = Ember.get(array, 'length');
    return length > 1 ? length + ' ' + (0, _emberInflector.pluralize)(suffixText) : length + ' ' + suffixText;
  }
});