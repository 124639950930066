define('slate-payroll-admin/components/list/pay-period-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    payPeriodLink: Ember.computed('payPeriod.id', function () {
      return '/account/pay-period/' + this.get('payPeriod.id') + '/';
    }),

    payPeriodPunchesLink: Ember.computed('payPeriodLink', function () {
      return this.get('payPeriodLink') + 'punches';
    }),

    payPeriodCloseoutLink: Ember.computed('payPeriodLink', function () {
      return this.get('payPeriodLink') + 'close';
    })
  });
});