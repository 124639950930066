define('slate-payroll-admin/routes/administration/data-explorer/index/reports/run', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.find('query', params.id);
    },
    afterModel: function afterModel(query) {
      var _this = this;

      var qid = query.get('id');

      Ember.$.post('/api/queries/' + qid + '/run').done(Ember.run.bind(this, function (result) {
        this.transitionTo('administration.data-explorer.index.reports.view.result', qid, result.queryResult._id);
      })).fail(function (err) {
        _this.send('error', err);
      });
    },


    actions: {
      willTransition: function willTransition() {
        return true;
      }
    }
  });
});