define('slate-payroll-admin/components/data-explorer/display-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sortProperty: ['name'],
    toDisplay: Ember.A(),
    sortedAvailableFields: Ember.computed.sort('availableFields', 'sortProperty'),

    availableFields: Ember.computed('modelName', 'toDisplay.[]', function () {
      var _this = this;

      var modelName = this.get('modelName');

      if (!modelName) {
        return Ember.A();
      }

      var fields = Ember.A(),
          Model = this.get('store').modelFor(modelName),
          modelTransformedAttrs = Ember.get(Model, 'transformedAttributes'),
          modelFields = Ember.get(Model, 'fields'),
          toDisplay = this.get('toDisplay');

      // Result is an Ember.map from employee fields, so we have
      // to transform it to this object format
      modelFields.forEach(function (kind, field) {
        var fieldData = {
          field: field,
          kind: kind,
          name: Ember.String.capitalize(Ember.String.decamelize(field).replace(/_/g, ' ')),
          isRelationship: kind !== 'attribute'
        };

        if (fieldData.isRelationship) {
          fieldData.relationshipModel = Ember.get(Model.typeForRelationship(field, _this.store), 'modelName');
        }

        fields.addObject(fieldData);
      });

      modelTransformedAttrs.forEach(function (kind, field) {
        var matchingField = fields.findBy('field', field);

        if (matchingField) {
          matchingField['is' + Ember.String.capitalize(kind)] = true;
          matchingField.type = kind;
        }
      });

      if (toDisplay && toDisplay.get('length') > 0) {
        toDisplay.forEach(function (displayField) {
          var field = fields.findBy('name', displayField.get('propertyDisplayName'));
          if (field && !field.isRelationship) {
            fields.removeObject(field);
          }
        });
      }

      return fields;
    })
  });
});