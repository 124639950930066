define('slate-payroll-admin/components/multi-select-option', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['multi-select-option', 'list-group-item'],
    classNameBindings: ['checked:checked'],

    init: function init() {
      this._super.apply(this, arguments);
      this._initObserver();
      this._optionLabelChange();
    },


    _initObserver: Ember.observer('option', 'for.optionLabelPath', function () {
      var _this = this;

      var option = this.get('option'),
          optionLabel = this.get('for.optionLabelPath');

      if (!option || !optionLabel) {
        return;
      }

      var notifyOptionLabelChange = function notifyOptionLabelChange() {
        _this.set('_optionLabelSourceDidChange', true);
      };

      Ember.addObserver(option, optionLabel, notifyOptionLabelChange);
    }),

    _optionLabelChange: Ember.observer('for.optionLabelPath', '_optionLabelSourceDidChange', function () {
      var _this2 = this;

      Ember.run.once(function () {
        if (_this2.get('_optionLabelSourceDidChange')) {
          _this2.set('_optionLabelSourceDidChange', false);
        }

        var optionLabelPath = _this2.get('for.optionLabelPath'),
            option = _this2.get('option');

        _this2.set('optionLabel', optionLabelPath ? Ember.get(option, optionLabelPath) : option);
      });
    }),

    checkedDidChange: Ember.observer('checked', function () {
      if (this.get('_didChangeBound')) {
        this.set('_didChangeBound', false);
      } else {
        this.get('changeSelection')(this.get('checked'), this.get('option'));
        // this.sendAction('changeSelection', this.get('checked'), this.get('option'));
      }
    }),

    click: function click() {
      this.toggleProperty('checked');
    }
  });
});