define('slate-payroll-admin/controllers/administration/help-articles/edit', ['exports', 'slate-payroll-admin/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_uxAjaxStatusHooks.default, {
    disableCategoryAdd: Ember.computed.or('noCategoryName', 'working'),
    noCategoryName: Ember.computed.not('categoryName'),

    actions: {
      saveChanges: function saveChanges() {
        var _this = this;

        var article = this.get('model');

        this.ajaxStart();

        article.save().then(function (savedArticle) {
          _this.ajaxSuccess();
          _this.transitionToRoute('administration.help-articles.view', savedArticle.get('id'));
        }).catch(this.ajaxError.bind(this));
      },
      addCategory: function addCategory(categoryName) {
        var _this2 = this;

        var helpCategory = this.store.createRecord('help-category', {
          name: categoryName
        });

        this.ajaxStart();

        helpCategory.save().then(function () {
          _this2.ajaxSuccess();
          _this2.setProperties({
            categoryPopover: false,
            categoryName: null,
            refreshCategories: true
          });
        }).catch(this.ajaxError.bind(this));
      },
      closePopover: function closePopover() {
        this.set('categoryPopover', false);
      }
    }
  });
});