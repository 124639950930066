define('slate-payroll-admin/routes/administration/company/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var companyId = this.modelFor('administration.company').get('id');

      return Ember.RSVP.hash({
        company: this.modelFor('administration.company'),
        payPeriods: this.store.query('pay-period', {
          company: companyId,
          start: {
            $gte: moment().dayOfYear(1).toDate()
          },
          end: {
            $lte: moment().dayOfYear(366).toDate()
          }
        }),
        punches: this.store.query('punch', {
          company: companyId,
          $and: [{
            created: {
              $gte: moment().subtract(7, 'days').startOf('day').toDate()
            }
          }, {
            created: {
              $lte: moment().toDate()
            }
          }]
        }),
        paycodes: this.store.query('company-paycode', { company: companyId })
      });
    }
  });
});