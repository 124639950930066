define('slate-payroll-admin/helpers/sum', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sum = sum;
  function sum(n) {
    return n.reduce(function (tot, num) {
      return tot + (num || 0);
    }, 0);
  }

  exports.default = Ember.Helper.helper(sum);
});