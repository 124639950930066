define('slate-payroll-admin/components/data-explorer/display-key-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['select-group', 'text-center', 'form-group'],
    sortProperty: ['name'],
    sortedDisplayKeys: Ember.computed.sort('availableDisplayKeys', 'sortProperty'),

    init: function init() {
      this._super.apply(this, arguments);
      this.valueChanged();
      this.sortedDisplayKeysChanged();
    },


    valueChanged: Ember.observer('selectedKey.field', function () {
      return this.set('value', this.get('selectedKey.field'));
    }),

    availableDisplayKeys: Ember.computed('modelName', function () {
      var modelName = this.get('modelName');

      if (!modelName) {
        return Ember.A();
      }

      var fields = Ember.A(),
          Model = this.get('store').modelFor(modelName),
          modelFields = Ember.get(Model, 'fields');

      modelFields.forEach(function (kind, field) {
        if (kind === 'attribute') {
          fields.addObject({
            field: field,
            name: Ember.String.capitalize(Ember.String.decamelize(field).replace(/_/g, ' '))
          });
        }
      });

      return fields;
    }),

    sortedDisplayKeysChanged: Ember.observer('availableDisplayKeys.[]', function () {
      var defaultKey = this.get('sortedDisplayKeys.firstObject');
      if (!this.get('selectedKey') && defaultKey) {
        this.set('selectedKey', defaultKey);
      }
    })
  });
});