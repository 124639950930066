define('slate-payroll-admin/controllers/administration/companies/add', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    needs: ['administration/companies/add/index'],
    logo: Ember.computed.reads('controllers.administration/companies/add/index.file.firstObject'),

    actions: {
      deleteRecord: function deleteRecord(record) {
        record.destroyRecord();
      },
      removeObject: function removeObject(target, item) {
        this.get(target).removeObject(item);
      }
    }
  });
});