define('slate-payroll-admin/routes/administration/changes-legacy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var queryMerge = ['company', 'employee', 'applied'];

  exports.default = Ember.Route.extend({
    queryParams: {
      company: {
        refreshModel: true
      },
      employee: {
        refreshModel: true
      },
      consumed: {
        refreshModel: true
      },
      applied: {
        refreshModel: true
      },
      sortCreatedAsc: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var query = {
        page: params.page - 1 || 0,
        limit: this.get('controller.limit') || 15
      };

      if (!params.consumed) {
        query.consumed = {
          $exists: false
        };
      }

      query.sort = {
        created: params.sortCreatedAsc ? 1 : -1
      };

      queryMerge.forEach(function (queryKey) {
        var paramValue = params[queryKey];

        if (paramValue !== null && paramValue !== undefined) {
          query[queryKey] = paramValue;
        }
      });

      return this.store.query('change-request', query);
    }
  });
});