define('slate-payroll-admin/components/x-bound-select-option', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'option',
    attributeBindings: ['value', 'selected'],

    selected: Ember.computed('selection', 'item', function () {
      var selection = this.get('selection'),
          item = this.get('item');

      return this.get('compareWithValue') ? selection === this.get('value') : selection === item;
    }),

    value: Ember.computed('optionValuePath', 'item', function () {
      var item = this.get('item'),
          valPath = this.get('optionValuePath');

      return valPath ? Ember.get(item, valPath) : item;
    })
  });
});