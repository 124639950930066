define('slate-payroll-admin/models/department', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    name: attr('string'),
    code: attr('string'),

    company: _emberData.default.belongsTo('company'),

    // Legacy
    legacyDepartmentId: attr('number'),

    created: attr('date')
  });
});