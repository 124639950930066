define('slate-payroll-admin/routes/administration/time-off-balances', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),

    model: function model() {
      return this.ajax.request('/admin-api/employee/balances');
    }
  });
});