define('slate-payroll-admin/controllers/administration/data-explorer/index/reports/view/result', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function generateRangesToCurrent(m, rangeType, workWeekStart) {
    var generate = true,
        now = moment(),
        ranges = Ember.A(),
        isWorkWeek = rangeType === 'work week',
        _ww = isNaN(workWeekStart) ? 7 : workWeekStart,
        _m = m;
    do {
      var _s = isWorkWeek ? moment(_m).day(_ww).startOf('day').toDate() : moment(_m).startOf(rangeType).toDate();

      ranges.pushObject({
        start: _s,
        end: isWorkWeek ? moment(_s).add(6, 'days').endOf('day').toDate() : moment(_m).endOf(rangeType).toDate()
      });

      if (moment(ranges.get('lastObject.end')).isAfter(now)) {
        generate = false;
      } else {
        _m.add(1, isWorkWeek ? 'week' : rangeType);
      }
    } while (generate);

    return ranges;
  } /* eslint-disable no-undef */


  function identityForRange(range) {
    var f = function f(d) {
      return moment(d).format('ddd, MMM Do YYYY');
    };
    return f(range.start) + ' - ' + f(range.end);
  }

  exports.default = Ember.Controller.extend({
    groups: Ember.computed('query.grouping{groupType,value}', function () {
      var grouping = this.get('query.grouping'),
          type = grouping ? grouping.groupType : grouping,
          isField = type === 'Field',
          rows = this.get('model.rows'),
          workWeekStart = this.session.get('currentUser.company.workWeekStart');

      var value = grouping ? grouping.value : grouping;

      if (!grouping || type !== 'Field' && type !== 'Time') {
        return false;
      }

      var groups = Ember.A();

      if (isField) {
        var uniqueFields = Ember.A();
        rows.forEach(function (row) {
          return uniqueFields.addObject(Ember.get(row, value));
        });

        uniqueFields.forEach(function (identity) {
          groups.pushObject({
            identity: identity,
            rows: rows.filterBy(value, identity)
          });
        });
      } else {
        value = value.toLowerCase();

        var sortedRows = rows.sortBy('created'),
            firstRow = sortedRows.get('firstObject'),
            timeKey = firstRow && firstRow.time_stamp ? 'time_stamp' : 'created';

        if (!firstRow) {
          return false;
        }

        var firstDate = moment(Ember.get(firstRow, timeKey));

        var ranges = generateRangesToCurrent(firstDate, value, workWeekStart);
        ranges.forEach(function (range) {
          return groups.pushObject({
            identity: identityForRange(range),
            rows: rows.filter(function (row) {
              var t = moment(Ember.get(row, timeKey));
              return t.isAfter(range.start) && t.isBefore(range.end);
            })
          });
        });
      }

      return groups;
    }),

    actions: {
      print: function print() {
        window.print();
      }
    }
  });
});