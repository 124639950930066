define('slate-payroll-admin/controllers/administration/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    countModels: ['companies', 'employees', 'users', 'company-paycodes', 'departments', 'locations', 'pay-periods', 'punches'],

    hasClockedInCount: Ember.computed('clockedInCount', function () {
      return typeof this.get('clockedInCount') === 'number' ? true : false;
    })
  });
});