define('slate-payroll-admin/mixins/ux-ajax-status-hooks', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    successMessageTimeout: 3,
    logging: Ember.inject.service(),

    __parseErrors: function __parseErrors(errTarget) {
      return Array.isArray(errTarget) ? errTarget.mapBy('detail').join(', ') : errTarget.detail || errTarget;
    },
    ajaxError: function ajaxError(e, user) {
      var errMsg = void 0;

      if (e && (e.errors || e.payload)) {
        errMsg = this.__parseErrors(e.errors || e.payload || e);
      } else {
        errMsg = e && e.responseText ? e.responseText : e;
      }

      if (e && e.status === 0) {
        this.logging.send(e);
        errMsg = 'Connection interrupted. Please try again. If this message persists, try disabling extensions.';
      }

      if (!user && e) {
        this.logging.send(e);
      }

      this.setProperties({
        working: false,
        errorMessage: errMsg
      });
    },
    ajaxSuccess: function ajaxSuccess(success) {
      var _this = this;

      this.setProperties({
        working: false,
        errorMessage: null,
        successMessage: success
      });

      Ember.run.later(function () {
        if (!_this.get('isDestroyed') && !_this.get('isDestroying')) {
          _this.set('successMessage', null);
        }
      }, this.get('successMessageTimeout') * 1000);
    },
    ajaxStart: function ajaxStart() {
      this.setProperties({
        working: true,
        errorMessage: null,
        successMessage: null
      });
    }
  });
});