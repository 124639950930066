define('slate-payroll-admin/initializers/inject-store-to-modules', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var initialize = exports.initialize = function initialize(app) {
    // console.debug('Init :: Injecting store');
    app.inject('modules:session', 'store', 'service:store');
    // app.inject('components', 'store', 'store:main');
  };

  exports.default = {
    name: 'inject-store-to-modules',
    after: 'ember-data',

    initialize: initialize
  };
});