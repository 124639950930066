define('slate-payroll-admin/controllers/administration/messaging/index', ['exports', 'slate-payroll-admin/mixins/controller-abstractions/pagination'], function (exports, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_pagination.default, {
    queryParams: ['page', 'limit', 'sortBy', 'sortAsc', 'box'],
    sortBy: 'created',
    sortAsc: false,
    limit: 20,
    page: 1,
    box: null,

    sortOptions: [{
      label: 'Message Date',
      value: 'created'
    }],

    boxOptions: [{
      label: 'All',
      value: null
    }, {
      label: 'Received',
      value: 'received'
    }, {
      label: 'Sent',
      value: 'sent'
    }]
  });
});