define('slate-payroll-admin/controllers/administration/company/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    recentPunchesChart: {
      chartOptions: {
        color: {
          pattern: ['#374140', '#3E606F', '#79BD8F', '#FFD96A', '#FF6138', '#333', '#999']
        },
        tooltip: {
          format: {
            title: function title(d) {
              return moment().subtract(7 - d, 'days').format('M/D/YY');
            }
          }
        },
        axis: {
          x: {
            tick: {
              format: function format(d) {
                return moment().subtract(7 - d, 'days').format('M/D');
              }
            }
          }
        }
      },
      dataOptions: {
        type: 'bar'
      }
    },

    recentPunchesDataset: Ember.computed('model.punches.[]', function () {
      var paycodes = this.get('model.paycodes'),
          punches = this.get('model.punches');

      var dataset = Ember.A();

      paycodes.forEach(function (paycode) {
        dataset.addObject(Ember.A([paycode.get('paycode.description')]));
      });

      this.set('recentPunchesChart.dataOptions.groups', [dataset.map(function (column) {
        return column.objectAt(0);
      })]);

      var getTotalsForDate = function getTotalsForDate(date) {
        return dataset.map(function (column) {
          var columnName = column.objectAt(0);
          return punches.reduce(function (total, punch) {
            if (moment(punch.get('created')).isSame(date, 'day') && punch.get('companyPaycode.paycode.description') === columnName) {
              return total + 1;
            } else {
              return total;
            }
          }, 0);
        });
      };

      var setTotals = function setTotals(totals) {
        totals.forEach(function (total, index) {
          dataset.objectAt(index).pushObject(total);
        });
      };

      for (var i = 0; i < 8; i++) {
        var date = moment().subtract(7 - i, 'days');
        setTotals(getTotalsForDate(date));
      }

      return dataset.filter(function (dataArray) {
        return dataArray.slice(1).find(function (val) {
          return val > 0;
        });
      }).toArray();
    }),

    currentActivePayPeriod: Ember.computed('model.payPeriods.@each.{start,end}', function () {
      return this.get('model.payPeriods').find(function (payPeriod) {
        return moment(payPeriod.get('start')).isBefore(moment()) && moment(payPeriod.get('end')).isAfter(moment()) && payPeriod.get('acceptPunches');
      });
    }),

    upcomingPayPeriods: Ember.computed.filter('model.payPeriods', function (payPeriod) {
      return moment(payPeriod.get('paidOn')).isAfter(moment());
    }),

    closedPayPeriods: Ember.computed.filter('model.payPeriods', function (payPeriod) {
      return !!payPeriod.get('closedOn');
    }),

    activePayPeriods: Ember.computed.filterBy('model.payPeriods', 'acceptPunches', true)
  });
});