define('slate-payroll-admin/components/lazy-model-select', ['exports', 'slate-payroll-admin/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LazyModelSelectComponent = Ember.Component.extend(_uxAjaxStatusHooks.default, {
    classNames: ['lazy-model-select-component'],
    value: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.instanceSelectionChanged();
      this.set('value', Ember.A());
    },
    didInsertElement: function didInsertElement() {
      this.resolveModel();
    },


    resolveModel: Ember.observer('modelName', 'refresh', function () {
      var _this = this;

      var select = 'id',
          selectors = ['optionLabelPath', 'optionValuePath', 'selectQuery'];

      if (!this.get('refresh') && !this.get('modelName')) {
        return;
      } else if (this.get('refresh')) {
        this.set('refresh', false);
      }

      this.ajaxStart();

      selectors.forEach(function (selector) {
        if (_this.get(selector)) {
          select += ' ' + _this.get(selector);
        }
      });

      this.store.query(this.get('modelName'), { select: select }).then(function (results) {
        _this.set('resolvedModel', results);
        _this.ajaxSuccess();
        if (_this.get('selection')) {
          _this.set('value', _this.get('selection'));
        }
      }).catch(this.ajaxError.bind(this));
    }),

    instanceSelectionChanged: Ember.observer('resolvedModel', 'value', function () {
      if (!this.get('resolvedModel')) {
        return;
      }

      var selection = this.get('value');

      var model = this.get('resolvedModel'),
          valuePath = this.get('optionValuePath');

      var update = model.find(function (item) {
        var _value = valuePath ? item.get(valuePath) : item;
        return selection === _value;
      });

      this.set('value', update);
      var updateCallback = this.get('action');
      updateCallback(update);
    })
  });

  LazyModelSelectComponent.reopenClass({
    positionalParams: ['modelName']
  });

  /*
    {{lazy-model-multi-select 'my-model'
      store=store
      selectId='my-select-id'
      optionLabelPath='model.label.path'
      value=value}}
  */

  exports.default = LazyModelSelectComponent;
});