define('slate-payroll-admin/controllers/administration/paycode', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this._getCompanyPaycodeCount();
    },


    _getCompanyPaycodeCount: Ember.observer('model.id', function () {
      var paycodeId = this.get('model.id'),
          self = this;

      if (!paycodeId) {
        return;
      }

      var promise = Ember.$.getJSON('/admin-api/company-paycodes', {
        paycode: paycodeId,
        _count: true
      }).then(function (result) {
        self.set('companyPaycodes', result.count);
      }).fail(function (err) {
        Ember.Logger.error(err);
        self.set('companyPaycodes', 'Failed to retrieve');
      });

      this.set('companyPaycodes', promise);
    }),

    actions: {
      deletePaycode: function deletePaycode() {
        var model = this.get('model'),
            self = this;

        this.set('deleting', true);

        model.destroyRecord().then(function () {
          self.set('deleting', false);
          self.transitionToRoute('administration.paycodes');
        });
      }
    }
  });
});