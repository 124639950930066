define('slate-payroll-admin/components/paystubs/paystub-grid-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    link: 'administration.company.paystub',
    paidOn: Ember.computed.or('paystub.{payPeriod.paidOn,payrollDate}'),

    actions: {
      print: function print() {
        var printAction = this.get('print');

        if (printAction && typeof printAction === 'function') {
          printAction(this.get('paystub.id'));
        }
      },
      download: function download() {
        var downloadAction = this.get('download');
        if (downloadAction && typeof downloadAction === 'function') {
          downloadAction(this.get('paystub.id'));
        }
      },
      delete: function _delete() {
        this.get('delete')(this.get('paystub'));
      }
    }
  });
});