define("slate-payroll-admin/utils/remove-null-references", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = removeNullReferences;
  function removeNullReferences(object) {
    var args = Array.prototype.slice.call(arguments, 1);

    args.forEach(function (ref) {
      if (object[ref] === null) {
        delete object[ref];
      }
    });
  }
});