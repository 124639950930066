define('slate-payroll-admin/models/data-point', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attr = _emberData.default.attr;

  exports.default = _emberData.default.Model.extend({
    propertyName: attr('string'),
    propertyKey: attr('string'),
    isRelationship: attr('boolean'),
    isDate: attr('boolean'),
    operator: attr('string'),
    propertyType: attr('string'),
    value: attr(),
    queryGroup: attr('number'),
    limiter: attr('boolean')
  });
});