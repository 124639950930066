define('slate-payroll-admin/routes/administration/admin-users/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var query = {
        page: params.page - 1 || 0,
        limit: this.get('controller.limit') || 20,
        sort: {
          created: -1
        }
      };

      return this.store.query('admin-user', query);
    }
  });
});