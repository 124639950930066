define('slate-payroll-admin/services/notify', ['exports', 'slate-payroll-admin/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var prox = function prox(method) {
    var args = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    return function () {
      var msg = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var fn = window.toastr[method];
      return args ? fn(msg.toString(), title.toString(), options) : fn();
    };
  };

  window.toastr.options = _environment.default.notifications || {};

  exports.default = Ember.Service.extend({
    success: prox('success'),
    info: prox('info'),
    warning: prox('warning'),
    error: prox('error'),
    clear: prox('clear', false),
    remove: prox('remove', false)
  });
});