define('slate-payroll-admin/models/message', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    subject: (0, _attr.default)('string'),
    body: (0, _attr.default)('string'),
    metadata: (0, _attr.default)(),
    company: (0, _relationships.belongsTo)('company', { async: true, inverse: null }),
    file: (0, _relationships.hasMany)('file-blob'),
    created: (0, _attr.default)('string', {
      defaultValue: function defaultValue() {
        return Date.now();
      }
    })
  });
});