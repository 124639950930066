define('slate-payroll-admin/components/x-navigation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'nav',
    role: 'navigation',
    classNames: ['navbar', 'navbar-default', 'app-navbar'],
    attributeBindings: ['role'],

    actions: {
      logout: function logout() {
        this.get('logout')();
      }
    }
  });
});