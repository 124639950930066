define('slate-payroll-admin/routes/administration/company/pay-periods/review-auto', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var generatedData = this.controllerFor('administration.company.pay-periods.add').get('generatedPayPeriods');
      return generatedData ? generatedData : this.transitionTo('administration.company.pay-periods.add');
    }
  });
});