define('slate-payroll-admin/components/form-step', ['exports', 'slate-payroll-admin/templates/components/form-step'], function (exports, _formStep) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _formStep.default,

    form: Ember.computed.alias('parentView'),

    show: Ember.computed('form.step', function () {
      return this.get('form.step') === this.get('_formIndex');
    }),

    _focus: function _focus() {
      if (this.get('autofocus') === false) {
        return;
      }

      Ember.run.scheduleOnce('afterRender', this, function () {
        var $input = this.$().find('input:first') || this.$().find('select:first');

        if ($input) {
          $input.focus();
        }
      });
    }
  });
});