define('slate-payroll-admin/components/markdown-textarea', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    value: Ember.computed.alias('markdownRaw'),

    textareaId: Ember.computed('elementId', function () {
      return 'markdown-input-' + this.get('elementId');
    }),

    markdownRendered: Ember.computed('markdownRaw', function () {
      var markdownRaw = this.get('markdownRaw');
      return markdownRaw ? markdown.toHTML(markdownRaw) : markdownRaw;
    }),

    actions: {
      setPreview: function setPreview(state) {
        this.set('preview', state);
      }
    }
  });
});