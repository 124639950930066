define('slate-payroll-admin/routes/administration/company/paycodes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      sortAsc: { refreshModel: true },
      sortBy: { refreshModel: true },
      page: { refreshModel: true }
    },

    model: function model(params) {
      var query = {
        page: params.page - 1 || 0,
        limit: this.get('controller.limit') || 20,
        sort: {},
        company: this.modelFor('administration.company').get('id')
      };

      query.sort[params.sortBy] = params.sortAsc ? -1 : 1;

      return this.store.query('company-paycode', query);
    },


    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});