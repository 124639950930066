define('slate-payroll-admin/modules/socket-events/event-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.create({
    events: Ember.A([
      // {
      //   name: 'data-transmission',
      //   hook: function ( data ) {
      //     console.log(data);
      //   }
      // }
    ])
  });
});